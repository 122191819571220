import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiService from "../../network/ApiService";

function Message() {
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
    fetchMessages,
  } = ApiService();

  const { organizationId } = useSelector((state) => state.organization);
  const [messages, setMessages] = useState(null);
  const [label, setLabel] = useState(null);
  const params = useParams();
  const messageId = params.messageId;
  const languageStatus = sessionStorage.getItem("language");
  const getMessage = async () => {
    try {
      const response = await fetchMessages();
      setMessages(response.data.mapOfIdAndDetails[messageId]);
      setLabel(response.data.mapOfIdAndDisplayLabels[messageId]);
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId && messageId) {
      getMessage();
    }
  }, [organizationId, messageId]);

  // const IMAGEURL = process.env.REACT_APP_IMAGE_URL;

  return (
      <>
    {/*    <section className="ftco-section ftco-no-pb ftco-no-pt mt-3">*/}
    {/*  <div className="container">*/}
    {/*    <div className="page-heading">*/}
    {/*      <h3 className="heading-text">*/}
    {/*        {label ? `${label.designationNP}को मन्तव्य` : null}*/}
    {/*      </h3>*/}
    {/*    </div>*/}
    {/*    {messages && messages.length > 0*/}
    {/*      ? messages.map((data, index) => {*/}
    {/*          return (*/}
    {/*            <div className="row no-gutters mb-4 message-image" key={index}>*/}
    {/*              <div className="col-md-6 d-flex align-items-stretch">*/}
    {/*                <div*/}
    {/*                  className="img img-3 w-100 d-flex justify-content-center align-items-center"*/}
    {/*                  // style={{*/}
    {/*                  //   backgroundImage: `url(${*/}
    {/*                  //     label.profileImg*/}
    {/*                  //       ? label.profileImg*/}
    {/*                  //       : `assets/images/team-1.jpg`*/}
    {/*                  //   })`,*/}
    {/*                  //   position: "relative",*/}
    {/*                  //   borderRadius: "5px 0 0 5px",*/}
    {/*                  // }}*/}
    {/*                  // style={{*/}
    {/*                  //   backgroundImage: `url(${*/}
    {/*                  //     label.profileImg*/}
    {/*                  //   })`,*/}
    {/*                  //   position: "relative",*/}
    {/*                  //   borderRadius: "5px 0 0 5px",*/}
    {/*                  // }}*/}
    {/*                >*/}
    {/*                  <img*/}
    {/*                    src={*/}
    {/*                      label.profileImg.trim() !== "N/A"*/}
    {/*                        ? label.profileImg*/}
    {/*                        : "assets/images/team-1.jpg"*/}
    {/*                    }*/}
    {/*                    style={{*/}
    {/*                      width: "100%",*/}
    {/*                      height: "auto",*/}
    {/*                      objectFit: "contain",*/}
    {/*                    }}*/}
    {/*                    alt="team"*/}
    {/*                  />*/}
    {/*                  /!* <a*/}
    {/*                    href="https://vimeo.com/45830194"*/}
    {/*                    className="play-video popup-vimeo d-flex align-items-center justify-content-center"*/}
    {/*                  >*/}
    {/*                    <span className="fa fa-play"></span>*/}
    {/*                  </a> *!/*/}
    {/*                </div>*/}
    {/*              </div>*/}
    {/*              <div className="col-md-6 wrap-about">*/}
    {/*                <div*/}
    {/*                  className="bg-light px-3 px-md-4 py-5 message-text"*/}
    {/*                  style={{*/}
    {/*                    color: "rgb(128, 128, 128)",*/}
    {/*                    borderRadius: "0 5px 5px 0",*/}
    {/*                  }}*/}
    {/*                >*/}
    {/*                  <div className="heading-section">*/}
    {/*                    <span className="subheading">{data.header}</span>*/}
    {/*                  </div>*/}
    
    {/*                  <div*/}
    {/*                    dangerouslySetInnerHTML={parseHtmlToView(data.message)}*/}
    {/*                  />*/}
    {/*                </div>*/}
    {/*              </div>*/}
    {/*            </div>*/}
    {/*          );*/}
    {/*        })*/}
    {/*      : null}*/}
    {/*  </div>*/}
    {/*</section>*/}
        {
          messages && messages.length > 0
              ? messages.map((data, index) => {
                return (
                    <div key={index}>
                      <div className="tm-page">
                        <div className="uk-section uk-section-default">
                            <div className="uk-container">
                                <div className="tm-grid-expand">
                                    <h2 className="uk-h2 uk-margin-large uk-text-center">
                                        <span className="underline-secondary">
                                          {
                                            languageStatus === 'en' ?
                                                <>
                                                  {label ? `${(label.designation).trim()}'s Speech` : null}
                                                </>
                                                :
                                                <>
                                                {label ? `${(label.designationNP).trim()}को मन्तव्य` : null}
                                              </>
                                          }
                                        </span>
                                    </h2>
                                </div>
                                <div uk-grid="true">
                                    <div className="uk-width-1-2@m">
                                        <div
                                            className="uk-tab-left"
                                            uk-tab={`connect: #component-tab-left; animation: uk-animation-fade`}>
                                          {label.profileImg.trim() !== "N/A" ?
                                              <div className="uk-card-media-top">
                                                      <div className="aspect-square message_image">
                                                          <img
                                                              decoding="async"
                                                              src={
                                                                label.profileImg.trim() !== "N/A"
                                                                    ? label.profileImg
                                                                    : "assets/images/image.jpg"
                                                              }
                                                              className="el-image"
                                                              alt=""
                                                              loading="lazy"
                                                          />
                                                      </div>
                                                  </div>
                                              : null}
                                        </div>
                                    </div>
                                    <div className="uk-width-expand@m">
                                      <div id="component-tab-left">
                                          <div uk-grid="true">
                                              <div className="uk-width-expand@m">
                                                  <div
                                                      className="uk-card uk-card-default uk-card-small uk-margin-remove-first-child uk-margin"
                                                      data-id="page#7-0-1-1"
                                                  >

                                                    <div className="uk-card-body">
                                                          {/*<h3 className="el-title uk-card-title uk-margin-top uk-margin-remove-bottom">*/}
                                                          {/*    {label ? `${label.designationNP} को मन्तव्य` : null}*/}
                                                          {/*</h3>*/}
                                                          <div
                                                              dangerouslySetInnerHTML={parseHtmlToView(languageStatus === 'en' ? (data.message ?? data.messageNp) : (data.messageNp ?? data.message))}
                                                          />
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
              ) } ) : null }
      </>
  );
}

export default Message;
