import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiService from "../../../network/ApiService";
import News from "./News";
import NewsSlice from "./NewsSlice";
import {Lightbox} from "yet-another-react-lightbox";
import {Thumbnails} from "yet-another-react-lightbox/plugins";

function NewsDetail() {
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
    fetchMessages,
    fetchNewsDetailById,
  } = ApiService();

  const { organizationId } = useSelector((state) => state.organization);
  const [newsDetail, setNewsDetail] = useState([]);
  const [open, setOpen] = useState(false);
  const [slides, setSlides] = useState([]);
  const languageStatus = sessionStorage.getItem("language");
  const params = useParams();
  const newsId = params.newsId;
  const getNewsDetails = async () => {
    try {
      const response = await fetchNewsDetailById(newsId);
      setNewsDetail(response.data.aboutUsNewsAndImg);
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId && newsId) {
      getNewsDetails();
    }
  }, [organizationId, newsId]);

  // const newsLists = useCallback(()=>{
  //   if (newsId) {
  //     return newsId;
  //   }
  // },[newsId])

  const handleViewSlides = (galleryImages) => {
    const images = [];
    galleryImages.map((value, item) => {
      images.push({
        src: value,
        alt: "image",
        width: 3840,
        height: 2560,
      });
    });
    setSlides(images);
    setOpen(!open);
    // console.log(images, "images");
  };

  return (
    <>
      {
        newsDetail && newsDetail.length > 0
            ? newsDetail.map((data, index) => {
              return (
                  <div key={index}>
                      <div className="tm-page">
                        <div className="uk-section uk-section-default">
                            <div className="uk-container">
                                <div className="tm-grid-expand">
                                    <h2 className="uk-h2 uk-margin-large uk-text-center">
                                        <span className="underline-secondary">{ languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header) }</span>
                                    </h2>
                                </div>
                                <div uk-grid="true">
                                    <div className="uk-width-1-2@m">
                                        <div
                                            className="uk-tab-left"
                                            uk-tab={`connect: #component-tab-left; animation: uk-animation-fade`}>
                                          {data.imageUrl.trim() !== "N/A" ?
                                              <div className="uk-card-media-top"
                                                   style={{
                                                     marginBottom: '71%'
                                                   }}>
                                                      <div className="aspect-square"
                                                      >
                                                          <img
                                                              decoding="async"
                                                              src={
                                                                data.imageUrl != "n/a"
                                                                    ? data.imageUrl
                                                                    : `assets/images/placeholder.jpg`
                                                              }
                                                              className="el-image"
                                                              alt=""
                                                              loading="lazy"
                                                          />
                                                      </div>
                                                  </div>
                                              : null}
                                        </div>
                                    </div>
                                    <div className="uk-width-expand@m">
                                      <div id="component-tab-left">
                                          <div uk-grid="true">
                                              <div className="uk-width-expand@m">
                                                  <div
                                                      className="uk-card uk-card-default uk-card-small uk-margin-remove-first-child uk-margin"
                                                      data-id="page#7-0-1-1"
                                                  >
                                                    <div className="uk-card-body">
                                                          {/*<h3 className="el-title uk-card-title uk-margin-top uk-margin-remove-bottom">*/}
                                                      {/*    {label ? `${label.designationNP} को मन्तव्य` : null}*/}
                                                      {/*</h3>*/}
                                                      <div
                                                          className="el-content" key={data.id}
                                                          style={{color: 'black'}}
                                                          dangerouslySetInnerHTML={parseHtmlToView(
                                                              (languageStatus === 'en' ? (data.message ?? data.messageNp) : (data.messageNp ?? data.message) )
                                                                  .split("<p><br></p>")
                                                                  .join("")
                                                              /* .substring(0, 200)*/
                                                          )}
                                                      >
                                                        </div>
                                                      </div>
                                                    {
                                                      (data.galleryUrls).length > 0 ?
                                                          <>
                                                            <div className="uk-flex uk-flex-column">
                                                              <h3 className="uk-text-center">{languageStatus === 'en' ? `Related Images` : 'सम्बन्धित चित्रहरू'}</h3>
                                                              <div className="uk-flex uk-flex-center uk-border-rounded">
                                                                <Lightbox
                                                                    open={open}
                                                                    close={() => setOpen(!open)}
                                                                    slides={slides}
                                                                    plugins={[Thumbnails]}
                                                                    carousel={{finite: true}}
                                                                />
                                                                {
                                                                  (data.galleryUrls).length > 0 ?
                                                                      <>
                                                                        <div
                                                                            className="uk-card uk-column-span news-gallery-image uk-overflow-hidden uk-margin-small-bottom"
                                                                            style={{ cursor: 'pointer', border:'1px solid #9E9E9E', padding:'5px', borderRadius: '5px'}}
                                                                            onClick={() => handleViewSlides(data.galleryUrls)}
                                                                        >
                                                                          <img
                                                                              decoding="async"
                                                                              className="el-image"
                                                                              alt=""
                                                                              loading="lazy"
                                                                              src={
                                                                                data.galleryUrls && data.galleryUrls !== "n/a"
                                                                                    ? data.galleryUrls
                                                                                    : "assets/images/image.jpg"
                                                                              }
                                                                          />
                                                                        </div>
                                                                      </> : null
                                                                }
                                                              </div>
                                                            </div>
                                                          </> : null
                                                    }
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
              ) } ) : null }
    </>
  );
}

export default NewsDetail;
