import React, {useEffect, useState} from "react";
import QRCode from "react-qr-code";

function QrCode(props) {
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    if (props.value) {
      setQrCode(props.value);
    }
  }, [props.value]);

  return (
    <>
      <QRCode
        // title="title"
        // value="https://play.google.com/store/apps/details?id=com.diyalotech.namunaShivnagar"
          value={qrCode}
        // fgColor="rgb(235, 93, 30)"
        // size="inherit"
      />
    </>
  );
}

export default QrCode;
