import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../../network/ApiService";

function Service(props) {
    const {organizationId} = useSelector((state) => state.organization);
    const {
        fetchServices,
    } = ApiService();

    const [serviceList, setServiceList] = useState([]);
    const languageStatus = sessionStorage.getItem("language");
    const getServices = async () => {
        try {
            const response = await fetchServices();
            setServiceList(response.data.messageWithImg);
            setActiveArray(response.data.messageWithImg[0])
        } catch (error) {
            console.log(error.message);
        }
    };

    const [activeArray, setActiveArray] = React.useState(null);
    const getActiveTab = (index) => {
        setActiveArray(serviceList[index])
    }

    const parseHtmlToView = (str) => {
        return {__html: str};
    };

    useEffect(() => {
        if (organizationId) {
            getServices();
        }
    }, [organizationId]);

    return (

        <>
            {serviceList ?
                <>
                    <div className="tm-page">
                        <div className="uk-section uk-section-default">
                            <div className="uk-container">
                                <div className="tm-grid-expand">
                                    <h2 className="uk-h2 uk-margin-large uk-text-center">
                                        <span className="underline-secondary">
                                            { languageStatus === 'en' ? `Our Services` : `हाम्रा सेवाहरू` }
                                        </span>
                                    </h2>
                                </div>
                                <div className={serviceList.length > 0 ? `uk-grid` : '' }>
                                    <div className="uk-width-1-5@m">
                                        <div
                                            className="uk-tab-left"
                                            uk-tab={`connect: #component-tab-left; animation: uk-animation-fade`}
                                        >
                                            {
                                                serviceList.map((data, index) => {
                                                    return (
                                                        <div key={`link_div_`+index}>
                                                            <a
                                                                href="#"
                                                                key={index}
                                                                onClick={() => getActiveTab(index)}
                                                            >{ languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header) }
                                                            </a>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <>
                                        {activeArray ?
                                            <>
                                                <div className="uk-width-expand@m" key={activeArray.id}>
                                                    <div id="component-tab-left">
                                                        <div className={serviceList.length > 0 ? `uk-grid` : '' }>
                                                            <div className="uk-width-expand@m">
                                                                <div
                                                                    className="uk-card uk-card-default uk-card-small uk-margin-remove-first-child uk-margin"
                                                                    data-id="page#7-0-1-1"
                                                                >
                                                                    {activeArray.imageUrl !== "n/a" ?
                                                                        <div className="uk-card-media-top">
                                                                            <div className="aspect-square" key={activeArray.id}>
                                                                                <img
                                                                                    decoding="async"
                                                                                    src={
                                                                                        activeArray.imageUrl !== "n/a"
                                                                                            ? activeArray.imageUrl
                                                                                            : `assets/images/image.jpg`
                                                                                    }
                                                                                    className="el-image"
                                                                                    alt=""
                                                                                    loading="lazy"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                    <div className="uk-card-body uk-margin-remove-first-child" key={activeArray.id}>
                                                                        <h3 className="el-title uk-card-title uk-margin-top uk-margin-remove-bottom">
                                                                            { languageStatus === 'en' ? (activeArray.header ?? activeArray.headerNp) : activeArray.headerNp }
                                                                        </h3>
                                                                        <div
                                                                            className="el-content" key={activeArray.id}
                                                                            style={{color: 'black'}}
                                                                            dangerouslySetInnerHTML={parseHtmlToView(
                                                                                (languageStatus === 'en' ? (activeArray.message ?? activeArray.messageNp) : (activeArray.messageNp ?? activeArray.message) )
                                                                                    .split("<p><br></p>")
                                                                                    .join("")
                                                                                /* .substring(0, 200)*/
                                                                            )}
                                                                        >
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>
                                                  <div
                                                      className="uk-flex-wrap-bottom uk-text-bold"
                                                      style={{fontSize: '24px'}}
                                                  >
                                                      { languageStatus === 'en' ? `Services Not Found` : `सेवाहरू भेटियन`}...
                                                  </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : null}
        </>
    );
}

export default Service;
