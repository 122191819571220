import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../../network/ApiService";
import MemberDetail from "./MemberDetail";
import "./Team.css";

function Member() {
  const { fetchStaffs } = ApiService();
  const { organizationId } = useSelector((state) => state.organization);
  const [memberList, setMemberList] = useState([]);
    const languageStatus = sessionStorage.getItem("language");

  const getCommitteeMembersList = async () => {
    try {
      const res = await fetchStaffs();
      setMemberList(res.data.committee);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (organizationId) {
      getCommitteeMembersList();
    }
  }, [organizationId]);
  return (
    <>
    {/*<section id="team" className="team section-bg">*/}
    {/*  <div className="container">*/}
    {/*    <div className="page-heading">*/}
    {/*      <h3 className="heading-text">सदस्य</h3>*/}
    {/*    </div>*/}

    {/*    <div className="accordion accordion-flush" id="accordionFlushExample">*/}
    {/*      {memberList.length > 0*/}
    {/*        ? memberList.map((data, key) => {*/}
    {/*            return (*/}
    {/*              <div key={key}>*/}
    {/*                <MemberDetail subHeaderItems={data} />*/}
    {/*              </div>*/}
    {/*            );*/}
    {/*          })*/}
    {/*        : null}*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</section>*/}
      <div className="tm-page">
        <div className="uk-section uk-section-default">
            <div className="uk-container">
                <ul uk-tab="animation: uk-animation-fade" className="uk-flex-center">
                    <li><a href="#">{ languageStatus === 'en' ? `Board Members` : `कार्यसमिति सदस्य`}</a></li>
                    <li><a href="#">{ languageStatus === 'en' ? `Accounting Committee` : `लेखा समिति`}</a></li>
                    <li><a href="#">{ languageStatus === 'en' ? `Office Staffs` : `कार्यालय कर्मचारी`}</a></li>
                </ul>

                <ul className="uk-switcher uk-margin-large">
                  {memberList.length > 0
                      ? memberList.map((data, key) => {
                        return (
                            <div key={key}>
                                <MemberDetail subHeaderItems={data} language={languageStatus} />
                            </div>
                        );
                      })
                      : null}
                </ul>
            </div>
        </div>
      </div>
    </>
  );
}

export default Member;
