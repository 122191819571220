import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

// import "swiper/css";
// import "swiper/css/bundle";

function Newsslides(props) {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    if (props.newsList.length > 0) {
      setNewsList(props.newsList);
    }
  }, [props.newsList]);
  return (
      <>
        <div className="notices uk-padding-small">
            <div className="uk-container">
                <div className="uk-flex">
                    <div className="notices_tag uk-flex uk-flex-middle"> { props.language === 'en' ? 'Notices' : `सूचनाहरू` }</div>

                    <div className="wrap wrap--logobar marquee marquee--hover-pause" id="containerElem">
                        <div className="marquee marquee--hover-pause">
                            <ul className="marquee__content">
                                {newsList.length
                                    ? newsList.map((data, index) => {
                                        return (
                                            <li className="list__item" style={{ listStyle: 'none', color:'red'}} key={index}>
                                                <Link
                                                    className="uk-link-reset"
                                                    to={`/news-detail/${data.newsId}`}
                                                >
                                                    {props.language === 'en' ?  (data.header ?? data.headerNp) : (data.headerNp ?? data.header)}
                                                </Link>
                                            </li>
                                        );
                                    })
                                    : null}
                            </ul>
                            <ul aria-hidden="true" className="marquee__content">
                                {newsList.length
                                    ? newsList.map((data, index) => {
                                        return (
                                            <li style={{ listStyle: 'none', color:'red'}} key={index}>
                                                <Link
                                                    className="uk-link-reset"
                                                    to={`/news-detail/${data.newsId}`}
                                                >
                                                    {props.language === 'en' ?  (data.header ?? data.headerNp) : (data.headerNp ?? data.header)}
                                                </Link>
                                            </li>
                                        );
                                    })
                                    : null}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    {/*    <div className="notices uk-padding-small">*/}
    {/*    <div className="uk-container">*/}
    {/*        <div className="uk-flex">*/}
    {/*            <div className="notices_tag uk-flex uk-flex-middle">Notices</div>*/}
    {/*            <div className="wrap wrap--logobar marquee marquee--hover-pause" id="containerElem">*/}
    {/*                <ul className="list " id="list" >*/}
    {/*                    {newsList.length*/}
    {/*                        ? newsList.map((data, index) => {*/}
    {/*                            return (*/}
    {/*                                <li className="list__item marquee__content" key={index}>*/}
    {/*                                    /!*<Link*!/*/}
    {/*                                    /!*    className="uk-link-reset"*!/*/}
    {/*                                    /!*    to={`/news-detail/${data.newsId}`}*!/*/}
    {/*                                    /!*>*!/*/}
    {/*                                        {data.header}*/}
    {/*                                    /!*</Link>*!/*/}
    {/*                                </li>*/}
    {/*                            );*/}
    {/*                        })*/}
    {/*                        : null}*/}
    {/*                </ul>*/}
    {/*                <ul aria-hidden="true" className="list" id="list" >*/}
    {/*                    {newsList.length*/}
    {/*                        ? newsList.map((data, index) => {*/}
    {/*                            return (*/}
    {/*                                <li className="list__item marquee__content" key={index}>*/}
    {/*                                    /!*<Link*!/*/}
    {/*                                    /!*    className="uk-link-reset"*!/*/}
    {/*                                    /!*    to={`/news-detail/${data.newsId}`}*!/*/}
    {/*                                    /!*>*!/*/}
    {/*                                    {data.header}*/}
    {/*                                    /!*</Link>*!/*/}
    {/*                                </li>*/}
    {/*                            );*/}
    {/*                        })*/}
    {/*                        : null}*/}
    {/*                </ul>*/}
    {/*            </div>*/}
    {/*        </div>*/}
    {/*    </div>*/}
    {/*</div>*/}
      </>
  );
}

export default Newsslides;
