import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Select from "react-select";
import {toast} from "react-toastify";
import ApiService from "../../../../network/ApiService";

function Complain() {
    const {fetchComplainTypes, complainEntry} = ApiService();
    const {organizationId} = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const [inputData, setInputData] = useState({
        orgId: organizationId,
        complainTypeId: null,
        complainDescription: "",
        complainersName: "",
        complainersNumber: "",
        complainersAddress: "",
    });

    const [options, setOptions] = useState([]);

    const getComplaintypes = async () => {
        try {
            const response = await fetchComplainTypes();
            setOptions(response.data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleChange = (e) => {
        //   return;
        const list = {...inputData};
        if (e.target) {
            const {name, value} = e.target;
            list[name] = value;
        } else {
            list["complainTypeId"] = e.value;
        }
        setInputData(list);
    };

    const submitComplain = async () => {
        try {
            const response = await complainEntry(inputData);
            setInputData({
                orgId: organizationId,
                complainTypeId: null,
                complainDescription: "",
                complainersName: "",
                complainersNumber: "",
                complainersAddress: "",
            });
            toast.success("Complain Submitted Successfully");
        } catch (error) {
            console.log(error)
            toast.error(error.response.data);
        }
    };
    useEffect(() => {
        if (organizationId) {
            getComplaintypes();
        }
    }, [organizationId]);

    return (
        <>
        <div className="uk-section uk-section-default section-contact">
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="uk-container">
                <h2
                    className="uk-h2 uk-text-center uk-margin-auto uk-scrollspy-inview uk-margin-large"
                    uk-scrollspy-class=""
                >
                    <span className="underline-secondary">
                        { languageStatus === 'en' ? `Complain Register` : `गुनासो सेवा`}
                    </span>
                </h2>
                <div className="uk-flex uk-flex-center">
                    <div className="uk-width-xlarge">
                        <div className="uk-flex uk-flex-column">
                            <label htmlFor="Your Name">{ languageStatus === 'en' ? `Name` : `नाम`}</label>
                            <input
                                type="text"
                                name="complainersName"
                                value={inputData.complainersName}
                                onChange={(e) => handleChange(e)}
                                className="uk-input"
                                id="name"
                                placeholder={languageStatus === 'en' ? `Name` : `नाम`}
                            />
                        </div>
                        <div className="uk-flex uk-flex-column uk-margin-top">
                            <label htmlFor="Your Name">{ languageStatus === 'en' ? `Address` : `ठेगाना`}</label>
                            <input
                                type="text"
                                className="uk-input"
                                name="complainersAddress"
                                value={inputData.complainersAddress}
                                onChange={(e) => handleChange(e)}
                                id="address"
                                placeholder={languageStatus === 'en' ? `Address` : `ठेगाना`}
                            />
                        </div>
                        <div className="uk-flex uk-flex-column uk-margin-top">
                            <label htmlFor="Your Name">{languageStatus === 'en' ? `Contact No.` : `सम्पर्क नम्बर`}</label>
                            <input
                                type="text"
                                name="complainersNumber"
                                value={inputData.complainersNumber}
                                onChange={(e) => handleChange(e)}
                                className="uk-input"
                                id="contact"
                                placeholder={languageStatus === 'en' ? `Contact No.` : `सम्पर्क नम्बर`}
                            />
                        </div>
                        <div className="uk-flex uk-flex-column uk-margin-top">
                            <label htmlFor="Your Name">{languageStatus === 'en' ? `Complain Type` : `गुनासो प्रकार`}</label>
                            <Select
                                isClearable={true}
                                isSearchable={true}
                                options={options}
                                name="complainTypeId"
                                placeholder={languageStatus === 'en' ? `Select Complain Type` : `गुनासो प्रकार छान्नुहोस्`}
                                // value={inputData.complainTypeId}
                                onChange={(e) => handleChange(e)}
                                id="complainTypeId"
                            />
                        </div>
                        <div className="uk-flex uk-flex-column uk-margin-top">
                            <label htmlFor="description">{languageStatus === 'en' ? `Complain Description` : `गुनासो विवरण`}</label>
                            <textarea
                                className="uk-textarea"
                                id="description"
                                name="complainDescription"
                                value={inputData.complainDescription}
                                onChange={(e) => handleChange(e)}
                                cols="30" rows="10"
                                placeholder={languageStatus === 'en' ? `Complain Description` : `गुनासो विवरण`}
                                />
                        </div>
                            <button
                                className="uk-button uk-button-primary uk-margin-small-left uk-align-right uk-margin-small-top"
                                onClick={() => submitComplain()}
                            >{languageStatus === 'en' ? `Submit` : `पेस गर्नुहोस`}</button>

                    </div>
                </div>
            </div>
            </form>
        </div>
      </>
    );
}

export default Complain;
