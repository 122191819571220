import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import QrCode from "../../includes/QrCode";

function AppSection(props) {
    const {orgDetailData} = useSelector((state) => state.contact);
    const {orgDetailDataApp} = useSelector((state) => state.contact);
    const [orgDetail, setOrgDetail] = useState({});
    const [orgDetailApp, setOrgDetailApp] = useState({});

    useEffect(() => {
        if (orgDetailData) {
            setOrgDetail(orgDetailData);
            setOrgDetailApp(orgDetailData);
        }
    }, [orgDetailData, orgDetailData]);

    return (
        <div
            className="uk-section uk-section-muted uk-position-relative section-mobile-app"
            // uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-bottom-medium; delay: false;"
        >
    <div className="uk-container">
        <div className="tm-grid-expand uk-grid">
            <div className="uk-width-1-1">
            </div>
            <div className="uk-width-1-2@m">
                <div className="uk-text-center">
                    <img
                        src={`assets/images/mobile-app.png`}
                        alt=""
                        className="uk-position-relative app-image"
                        style={{
                            height: '360px',
                            zIndex: '3'
                        }}
                    />
                </div>
            </div>
            <div className="uk-width-1-2@m uk-grid-item-match uk-flex-middle">
                <div className="uk-panel uk-margin-remove-first-child uk-margin uk-width-large uk-margin-top" data-id="page#6-0-1-0">
                    {orgDetailApp ?
                        <h2 className="uk-h2 el-title uk-margin-bottom">
                            {orgDetailApp ? (props.language === 'en' ? (orgDetailApp.nameEnglish ?? orgDetailApp.nameNepali) :orgDetailApp.nameNepali) : ''} - &nbsp;
                            <span className="underline-secondary">
                                {props.language === 'en' ?  'Mobile App' : `मोबाइल एप`}
                            </span>
                        </h2>
                        : null}
                    <div className="el-content uk-panel uk-margin-medium">
                        {
                            props.language === 'en' ?
                                <>
                                    The Khanepani Mobile App is a user-friendly app developed by <a target="_blank" href="https://diyalotech.com/">Diyalo Technology</a> that simplifies the process of paying water bills, checking water usage, update khanepani new/notice and getting alerts about water quality in dedicated water supply areas.
                                </>
                                :
                                <>
                                    खानेपानी मोबाइल एप एक प्रयोगकर्ता-मैत्री एप हो जुन <a target="_blank" href="https://diyalotech.com/">दियालो टेक्नोलोजी</a> द्वारा विकसित गरिएको छ जसले पानीको बिल तिर्ने, पानीको उपयोग जाँच गर्ने र नेपालमा पानी आपूर्ति अवरोधहरू बारे अलर्टहरू प्राप्त गर्ने प्रक्रियालाई सरल बनाउँछ।
                                </>
                        }
                    </div>
                    <div className="uk-flex uk-margin app_section">
                        {/*<a*/}
                        {/*    href="#"*/}
                        {/*    target="_blank"*/}
                        {/*>*/}
                        {/*    <img*/}
                        {/*        src={`assets/images/appstore.png`}*/}
                        {/*        className="uk-margin-small-right"*/}
                        {/*        alt=""*/}
                        {/*        style={{*/}
                        {/*            width: '180px',*/}
                        {/*            borderRadius: '8px'*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</a>*/}
                        <a
                            href={orgDetail.hasOwnProperty('playstoreUrl') ? orgDetail.playstoreUrl : `https://play.google.com/store/apps/details?id=com.diyalo.bussewa`}
                            target="_blank"
                        >
                            <img
                                src={`assets/images/google-play.png`} alt="" style={{
                                width: '180px',
                                borderRadius: '8px',
                                height: '53px'
                            }}
                            />
                        </a>
                        <div className="uk-margin-left uk-width-1-4@m">
                            <div className="svg-square">
                                <QrCode value={orgDetail.hasOwnProperty('playstoreUrl') ? orgDetail.playstoreUrl : `https://play.google.com/store/apps/details?id=com.diyalo.bussewa`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="left uk-position-absolute uk-visible@m"></div>
</div>
    );
}

export default AppSection;
