import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../../network/ApiService";
import {toast} from "react-toastify";
import engToNepNumber from "../../../helpers/EngToNepNumber";
import engToNepAccountProfile from "../../../helpers/EngToNepAccountProfile";

function Profile(props) {
    const {
        fetchAccountProfile,
        fetchCustomerCountProfile
    } = ApiService();
    const {orgDetailData} = useSelector((state) => state.contact);
    const [orgDetail, setOrgDetail] = useState({});
    const [accountProfile, setAccountProfile] = useState([]);
    const [customerProfile, setCustomerProfile] = useState([]);

    const getAccountProfile = async () => {
        try {
            const response = await fetchAccountProfile();
            setAccountProfile(response.data.resultList);
        } catch (error) {
            toast.error("कुनै खाता प्रोफाइल फेला परेन");
        }
    }

    const getCustomerProfile = async () => {
        try {
            const response = await fetchCustomerCountProfile();
            setCustomerProfile(response.data.status)
        } catch (error) {
            // Something Went Wrong, Please Contact System Administrator
            toast.error("कुनै ग्राहक भेटिएन");
        }
    }

    useEffect(() => {
        if (orgDetailData) {
            setOrgDetail(orgDetailData);
            getAccountProfile();
            getCustomerProfile();
        }
    }, [orgDetailData]);

    return (
        <div
            className="uk-section uk-section-default uk-preserve-color uk-section uk-position-relative section-services section-profile"
            // uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-bottom-medium; delay: false;"
        >
    <div className="uk-container">
        <div className="tm-grid-expand uk-child-width-1-1 uk-grid-margin uk-grid uk-grid-stack" uk-grid="">
            <div className="uk-width-1-1 uk-first-column">
                <h2
                    className="uk-h2 uk-text-center uk-margin-auto uk-scrollspy-inview"
                    data-id="page#3-0-0-0"
                >
                    <span className="underline-secondary">
                        {props.language === 'en' ?  'Account Profile' : `संस्थागत प्रोफाइल`}
                    </span>
                </h2>
                <div className="uk-margin-large uk-margin-auto uk-text-left">
                    <div
                        className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-5@m uk-grid-medium uk-grid-match uk-grid"
                        uk-grid=""
                    >
                        {
                            accountProfile.length > 0 ?
                                accountProfile.map((data, index) => {
                                    return (
                                        <div className="uk-width-1-3@s uk-first-column" key={index}>
                                            <div
                                                className="el-item uk-margin-auto uk-width-large uk-card uk-card-default uk-card uk-card-body uk-margin-remove-first-child uk-scrollspy-inview"
                                            >
                                                <div className="uk-child-width-expand uk-grid" uk-grid="true">
                                                    <div className="uk-width-auto@s uk-first-column">
                                                        <span
                                                            className="el-image uk-icon"
                                                            uk-icon="icon: info; width: 24; height: 24;"
                                                        ></span>
                                                    </div>
                                                    <div className="uk-margin-remove-first-child">
                                                        <h3 className="el-title uk-card-title uk-text-primary uk-margin-top uk-margin-remove-bottom">
                                                            {props.language === 'en' ?  data.entityName : engToNepAccountProfile(data.entityName)}
                                                        </h3>
                                                        <div className="el-content uk-panel">
                                                            {props.language === 'en' ? `Total Amount` : `कुल रकम` }:
                                                            <span className="uk-h5 uk-text-bold uk-margin-small-left">
                                                                {props.language === 'en' ? Math.abs(data.periodBalance) : engToNepNumber(Math.abs(data.periodBalance))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <>
                                    <div
                                        className="uk-grid uk-width-1-1 uk-h3" uk-grid="true" style={{
                                        border: "1px solid rgba(108, 108, 108, 0.9)",
                                        padding: "10px",
                                        borderRadius: "0 5px 5px 0",
                                    }}
                                    >
                                        {props.language === 'en' ?  'No Account Profile Found' : `कुनै खाता प्रोफाइल फेला परेन`}...
                                    </div>
                                </>
                        }
                        {
                            customerProfile > 0 ?
                                <div className="uk-width-1-3@s uk-first-column">
                                <div
                                    className="el-item uk-margin-auto uk-width-large uk-card uk-card-default uk-card uk-card-body uk-margin-remove-first-child uk-scrollspy-inview"
                                >
                                    <div className="uk-child-width-expand uk-grid" uk-grid="true">
                                        <div className="uk-width-auto@s uk-first-column">
                                            <span
                                                className="el-image uk-icon"
                                                uk-icon="icon: info; width: 24; height: 24;"
                                            ></span>
                                        </div>
                                        <div className="uk-margin-remove-first-child">
                                            <h3 className="el-title uk-card-title uk-text-primary uk-margin-top uk-margin-remove-bottom">
                                                { props.language === 'en' ? `Members` : 'सदस्य' }
                                            </h3>

                                            <div className="el-content uk-panel">
                                                {props.language === 'en' ? `No. of Members` : `सदस्य संख्या` }
                                                <span className="uk-h5 uk-text-bold uk-margin-small-left">
                                                    {props.language === 'en' ? customerProfile :engToNepNumber(customerProfile)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                :
                                <>
                                <div
                                    className="uk-grid uk-width-1-1 uk-h3" uk-grid="true"
                                    style={{
                                        border: "1px solid rgba(108, 108, 108, 0.9)",
                                        padding: "10px",
                                        borderRadius: "0 5px 5px 0",
                                    }}
                                >
                                    {props.language === 'en' ?  'No Customers Found' : `कुनै ग्राहक भेटिएन`}...
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    );
}

export default Profile;
