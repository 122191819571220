import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import QrCode from "../../includes/QrCode";

function Eservice(props) {
  const { orgDetailData } = useSelector((state) => state.contact);
  const [orgDetail, setOrgDetail] = useState({});

  useEffect(() => {
    if (orgDetailData) {
      setOrgDetail(orgDetailData);
    }
  }, [orgDetailData]);

  return (
    // <div className="col-md-11 row mx-auto">
    //   <div
    //     className="col-md-10 p-2 text-white our-services rounded"
    //   >
    //     <label>ई-सर्विसेस</label>
    //     <div className="d-flex">
    //       <div
    //         className="card col-md-5 ms-lg-5 p-2 border-0 bg-light"
    //         style={{ color: "black" }}
    //       >
    //         <div className="card-header bg-transparent border-0 p-0">
    //           <Link to="/outstanding-report" className="btn btn-primary btn-sm">
    //             बक्यौता विवरण
    //           </Link>
    //         </div>
    //         <div className="card-body p-0">
    //           {/* <p className="card-text">
    //             With supporting text below as a natural lead-in to additional
    //             content.
    //           </p> */}
    //         </div>
    //       </div>
    //
    //       <div
    //         className="card col-md-5 ms-lg-5 p-2 border-0 bg-light"
    //         style={{ color: "black" }}
    //       >
    //         <div className="card-header bg-transparent border-0 p-0">
    //           <Link to="/water-schedule" className="btn btn-primary btn-sm">
    //             पानी बितरण तालिका
    //           </Link>
    //         </div>
    //         <div className="card-body p-0">
    //           {/* <p className="card-text">
    //             With supporting text below as a natural lead-in to additional
    //             content.
    //           </p> */}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="d-flex mt-2">
    //       <div
    //         className="card col-md-5 ms-lg-5 p-2 border-0 bg-light"
    //         style={{ color: "black" }}
    //       >
    //         <div className="card-header bg-transparent border-0 p-0">
    //           <Link to="/complain-service" className="btn btn-primary btn-sm">
    //             गुनासो सेवा र ट्र्याक
    //           </Link>
    //         </div>
    //         <div className="card-body p-0">
    //           {/* <p className="card-text">
    //             With supporting text below as a natural lead-in to additional
    //             content.
    //           </p> */}
    //         </div>
    //       </div>
    //       <div
    //         className="card col-md-5 ms-lg-5 p-2 border-0 bg-light"
    //         style={{ color: "black" }}
    //       >
    //         <div className="card-header bg-transparent border-0 p-0">
    //           <Link
    //             to="/meter-reading-schedule"
    //             className="btn btn-primary btn-sm"
    //           >
    //             रिडिङ्ग तालिका
    //           </Link>
    //         </div>
    //         <div className="card-body p-0">
    //           {/* <p className="card-text">
    //             With supporting text below as a natural lead-in to additional
    //             content.
    //           </p> */}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="col-md-2 d-flex flex-column align-items-center">
    //     {orgDetail ? <p>{orgDetail.slogan}</p> : null}
    //     {/* <p>"सुरक्षित पानी, स्वास्थ्य जिन्दगानी"</p> */}
    //     {console.log(orgDetail,'orgdetail')}
    //       <a
    //         href="https://play.google.com/store/apps/details?id=com.diyalotech.namunaShivnagar"
    //         target="_blank"
    //       >
    //         <img
    //           style={{ borderRadius: "5px", width: "100%" }}
    //           // src="assets/images/new-google-play.png"
    //           src={`${process.env.PUBLIC_URL}/assets/images/new-google-play.png`}
    //           alt=""
    //         />
    //         {/* <span className="ms-3 app-link-heading">Get On Mobile</span> */}
    //       </a>
    //     <div
    //       className="col-md-8"
    //       style={{
    //         backgroundColor: "white",
    //         borderRadius: "5px",
    //         margin: "15px 0",
    //       }}
    //     >
    //       <QrCode />
    //     </div>
    //   </div>
    // </div>

      <div
          id="page#2"
          className="section-services uk-position-relative uk-section-primary uk-section"
          uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-bottom-medium; delay: false;"
      >
    <div className="uk-container">
        <div className="tm-grid-expand uk-child-width-1-1 uk-grid-margin uk-grid uk-grid-stack" uk-grid="true">
            <div className="uk-grid-item-match uk-width-1-1 uk-first-column">
                <div className="uk-panel uk-width-1-1">
                    <h2
                        className="uk-h2 uk-text-secondary uk-width-2xlarge uk-margin-auto uk-scrollspy-inview"
                        data-id="page#2-0-0-1"
                        uk-scrollspy-class="true"
                    ><span className="underline-secondary">
                        {props.language === 'en' ?  'Our Services' : `हाम्रा सेवाहरू`}
                    </span></h2>
                    <div data-id="page#2-0-0-2" className="uk-margin-large uk-width-2xlarge uk-margin-auto">
                        <div
                            className="uk-child-width-1-1 uk-child-width-1-2@s uk-grid-large uk-grid-match uk-grid"
                            uk-grid="true"
                        >
                            <div className="uk-first-column">
                                <div
                                    className="el-item uk-panel uk-margin-remove-first-child uk-scrollspy-inview"
                                    uk-scrollspy-class="true"
                                >
                                    {/*<span*/}
                                    {/*    className="el-image uk-icon"*/}
                                    {/*    uk-icon="icon: info; width: 24; height: 24;"*/}
                                    {/*></span>*/}

                                    <h3 className="el-title uk-margin-top uk-margin-remove-bottom">
                                        <Link to="/outstanding-report" className="uk-button uk-button-default">
                                            {props.language === 'en' ?  'Outstanding Details' : `बक्यौता विवरण`}
                                        </Link>
                                    </h3>


                                    <div className="el-content uk-panel uk-margin-small-top">
                                        {props.language === 'en' ?  'In outstanding Report customers can  view their outstanding due amount on a website and enhance convenience and transparency. It enables customers to monitor their account status easily' : `बक्यौता विवरणमा  ग्राहकहरूले  भुक्तानीहरू गरिएको वा नगरिएको, ओभरड्यूहरु व्यवस्थापन गर्न र ट्र्याक गर्न सकिन्छ।`}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="el-item uk-panel uk-margin-remove-first-child uk-scrollspy-inview"
                                    // uk-scrollspy-class=""
                                >
                                    {/*<span*/}
                                    {/*    className="el-image uk-icon"*/}
                                    {/*    uk-icon="icon: info; width: 24; height: 24;"*/}
                                    {/*></span>*/}

                                    <h3 className="el-title uk-margin-top uk-margin-remove-bottom">
                                        <Link to="/water-schedule" className="uk-button uk-button-default">
                                            {props.language === 'en' ?  'Water Distribution Schedule' : `पानी बितरण तालिका`}
                                        </Link>
                                    </h3>

                                    <div className="el-content uk-panel uk-margin-small-top">
                                        {props.language === 'en' ?  'A water schedule usually includes the time period specified by the water distribution company for billing and usage, as well as the amount of water the customer can receive during that period.' : `पानी वितरण तालिका मा पानी वितरणको समय, स्थान जस्ता जानकारी हेर्न सकिन्छ।`}
                                    </div>
                                </div>
                            </div>
                            <div className="uk-grid-margin uk-first-column">
                                <div
                                    className="el-item uk-panel uk-margin-remove-first-child uk-scrollspy-inview"
                                    // uk-scrollspy-class=""

                                >
                                    {/*<span*/}
                                    {/*    className="el-image uk-icon"*/}
                                    {/*    uk-icon="icon: info; width: 24; height: 24;"*/}
                                    {/*></span>*/}

                                    <h3 className="el-title uk-margin-top uk-margin-remove-bottom">
                                        <Link to="/complain-service" className="uk-button uk-button-default">
                                            {props.language === 'en' ?  'Complain Register' : `गुनासो सेवा`}
                                        </Link>
                                    </h3>

                                    <div className="el-content uk-panel uk-margin-small-top">
                                        {props.language === 'en' ?  'A customer complaint submission feature on a website allows customers to easily submit complaints or feedback, leading to improved customer satisfaction and loyalty' : `गुनासो सेवा र ट्र्याकमा  गुनासो पेश गर्न  र गुनासोहरूको स्थिति ट्र्याक गर्न सकिन्छ।`}
                                    </div>
                                </div>
                            </div>
                            <div className="uk-grid-margin">
                                <div
                                    className="el-item uk-panel uk-margin-remove-first-child uk-scrollspy-inview"
                                    // uk-scrollspy-class=""

                                >
                                    {/*<span*/}
                                    {/*    className="el-image uk-icon"*/}
                                    {/*    uk-icon="icon: info; width: 24; height: 24;"*/}
                                    {/*></span>*/}

                                    <h3 className="el-title uk-margin-top uk-margin-remove-bottom">
                                        <Link to="/meter-reading-schedule" className="uk-button uk-button-default">
                                            {props.language === 'en' ?  'Meter Reading Schedule' : `रिडिङ्ग तालिका`}
                                        </Link>
                                    </h3>

                                    <div className="el-content uk-panel uk-margin-small-top">
                                        {props.language === 'en' ?  'A meter reading schedule informs customers when their water meter will be read and how often' : `मिटर रीडिंग तालिका मा क्षेत्र अनुसार हुने रीडिंगको तालिका अध्ययन गर्न सकिन्छ ।`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-id="page#2-0-0-3"   className="uk-scrollspy-inview">
                        <div className="left uk-position-absolute uk-visible@m"></div>
                        <div className="right uk-position-absolute uk-visible@m"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
}

export default Eservice;
