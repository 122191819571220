import React, {useEffect, useState} from "react";
import "./Gallery.css";
import "yet-another-react-lightbox/styles.css";
import ApiService from "../../../network/ApiService";
import {useSelector} from "react-redux";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function Gallery() {
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
    } = ApiService();
    const {organizationId} = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const [open, setOpen] = useState(false);
    const [slides, setSlides] = useState([
        {
            src: "https://cdn.pixabay.com/photo/2016/12/17/14/33/wave-1913559_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
        {
            src: "https://cdn.pixabay.com/photo/2016/04/15/04/02/water-1330252_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
        {
            src: "https://cdn.pixabay.com/photo/2016/11/06/05/36/lake-1802337_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
        {
            src: "https://cdn.pixabay.com/photo/2013/10/09/02/27/lake-192990_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
        {
            src: "https://cdn.pixabay.com/photo/2014/10/26/17/19/fisherman-504098_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
        {
            src: "https://cdn.pixabay.com/photo/2016/10/22/15/32/water-1761027_960_720.jpg",
            alt: "image",
            width: 3840,
            height: 2560,
        },
    ]);

    const [galleryData, setGalleryData] = useState([]);

    const fetchGalleryImages = async () => {
        try {
            const response = await fetchGalleries();
            setGalleryData(response.data.messageWithImg);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleViewSlides = (galleryImages) => {
        const images = [];
        galleryImages.map((value, item) => {
            images.push({
                src: value,
                alt: "image",
                width: 3840,
                height: 2560,
            });
        });
        setSlides(images);
        setOpen(!open);
    };

    // useEffect(()=>{
    //   if (slides.length > 0) {

    //   }
    // },[slides])

    useEffect(() => {
        if (organizationId) {
            fetchGalleryImages();
        }
    }, [organizationId]);

    return (
        <>
    {/*  <div>*/}
            {/*  <div className="container">*/}
            {/*    <div className="page-heading">*/}
            {/*      <h3 className="heading-text">फोटो ग्यालरी</h3>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <Lightbox*/}
            {/*    open={open}*/}
            {/*    close={() => setOpen(!open)}*/}
            {/*    slides={slides}*/}
            {/*    plugins={[Thumbnails]}*/}
            {/*    carousel={{ finite: true }}*/}
            {/*  />*/}
            {/*  <div className="gallery-section">*/}
            {/*    <div className="row mx-2">*/}
            {/*      {galleryData.length > 0*/}
            {/*        ? galleryData.map((data, index) => {*/}
            {/*            return (*/}
            {/*              <div*/}
            {/*                className="col-md-4 mb-2 gallery-image"*/}
            {/*                key={index}*/}
            {/*                onClick={() => handleViewSlides(data.galleryUrls)}*/}
            {/*              >*/}
            {/*                <img*/}
            {/*                  src={*/}
            {/*                    data.imageUrl && data.imageUrl !== "n/a"*/}
            {/*                      ? data.imageUrl*/}
            {/*                      : "assets/images/about.jpg"*/}
            {/*                  }*/}
            {/*                />*/}
            {/*                <div className="img-label">*/}
            {/*                  <span className="text">{data.header}</span>*/}
            {/*                </div>*/}
            {/*              </div>*/}
            {/*            );*/}
            {/*          })*/}
            {/*        : null}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="uk-section-small uk-section-default section-contact">
            <div className="uk-container">
                <h2
                    className="uk-h2 uk-text-center uk-margin-auto uk-scrollspy-inview uk-margin-large"
                    uk-scrollspy-class=""
                >
                    <span className="underline-secondary">
                        { languageStatus === 'en' ? `Photo Gallery` : `फोटो ग्यालरी`}
                    </span>
                </h2>
                {galleryData.length > 0 ?
                    <>
                <div className="uk-grid-margin uk-container">
                    <div className="tm-grid-expand uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="true">
                        <div className="uk-width-1-1 uk-first-column">
                            <div data-id="page#1-0-0-0" className="uk-margin uk-text-center">
                                <div
                                    className="uk-child-width-1-1 uk-child-width-1-4@m uk-grid"
                                    uk-grid="true"
                                    uk-lightbox="toggle: a[data-type];"
                                >
                                    {galleryData.length > 0 ? galleryData.map((data, index) => {
                                            return (
                                              <div className="" key={index}>
                                                <div className="uk-light">
                                                    <a
                                                        className="el-item uk-inline-clip uk-transition-toggle uk-link-toggle customize-unpreviewable"
                                                        href={
                                                            data.imageUrl && data.imageUrl !== "n/a"
                                                                ? data.imageUrl
                                                                : "assets/images/image.jpg"
                                                        }
                                                        data-type="image"
                                                        data-caption={`<h4 class="uk-margin-remove">${languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header)}</h4>`}
                                                    >
                                                      <div className="uk-position-cover gallery-overlay"></div>
                                                      <picture>
                                                        <img
                                                            key={index}
                                                            decoding="async"
                                                            src={
                                                                data.imageUrl && data.imageUrl !== "n/a"
                                                                    ? data.imageUrl
                                                                    : "assets/images/image.jpg"
                                                            }
                                                            width="900"
                                                            height="600"
                                                            alt=""
                                                            loading="lazy"
                                                            className="el-image uk-transition-opaque"
                                                        />
                                                      </picture>
                                                    </a>
                                                  <div
                                                      // className="uk-position-bottom-left"
                                                  >
                                                    <div className="uk-panel uk-padding-small uk-margin-remove-first-child">
                                                      <h3 className="el-title uk-margin-top uk-margin-remove-bottom uk-text-italic uk-text-small" style={{ color: 'black'}}> { languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header) } </h3>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                            )
                                        })
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </> : <>
                    <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>
                      <div
                          className="uk-flex-wrap-bottom uk-text-bold"
                          style={{fontSize: '24px'}}
                      >
                          { languageStatus === 'en' ? `Photo Gallery Not Found` : `फोटो ग्यालरी भेटियन`}...
                      </div>
                    </div>
                </>}
            </div>
        </div>
    </>
    );
}

export default Gallery;
