import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../network/ApiService";
import engToNepNumber from "../../helpers/EngToNepNumber";
import {Link} from "react-router-dom";

function DownloadDetails({detailId, setAboutUsId}) {
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchDownloads,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const [downloadDocuments, setDownloadDocuments] = useState([]);
    const languageStatus = sessionStorage.getItem("language");
    const getDownloadDocuments = async () => {
        try {
            const response = await fetchDownloads();
            setDownloadDocuments(response.data.messageWithImg);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (organizationId) {
            getDownloadDocuments();
        }
    }, [organizationId]);

    return (
        <>
      {/*<section className="ftco-section ftco-no-pb ftco-no-pt mt-3">*/}
            {/*  <div className="container">*/}
            {/*    <div className="page-heading">*/}
            {/*      <h3 className="heading-text">डाउनलोड</h3>*/}
            {/*    </div>*/}
            {/*    <div className="row no-gutters">*/}
            {/*      <div className="col-md-12 d-flex align-items-stretch">*/}
            {/*        <table className="table table-striped">*/}
            {/*          <tbody>*/}
            {/*            {downloadDocuments.length > 0*/}
            {/*              ? downloadDocuments.map((data, index) => {*/}
            {/*                  return (*/}
            {/*                    <tr key={index}>*/}
            {/*                      <th>{index + 1}</th>*/}
            {/*                      <td>{data.header}</td>*/}
            {/*                      <td><a href={data.imageUrl} target="_blank" download><i className="fa fa-sharp fa-solid fa-download"></i></a></td>*/}
            {/*                    </tr>*/}
            {/*                  );*/}
            {/*                })*/}
            {/*              : null}*/}
            {/*          </tbody>*/}
            {/*        </table>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-2 d-flex align-items-stretch">*/}
            {/*         /!* <News newsDetails={true} />  *!/*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</section>*/}
            <div className="uk-section uk-section-default section-contact">
            <div className="uk-container">
                        <h2
                            className="uk-h2 uk-text-center uk-margin-auto uk-scrollspy-inview uk-margin-large uk-flex uk-flex-between"
                            uk-scrollspy-class=""
                        >
                            <span className="underline-secondary">
                                {languageStatus === 'en' ? (downloadDocuments[0]?.header ?? downloadDocuments[0]?.headerNp ?? '' ) + ` - Download` : (downloadDocuments[0]?.headerNp ?? downloadDocuments[0]?.header ?? '' ) + ` - डाउनलोड`}
                            </span>
                            <Link onClick={() => setAboutUsId(null)} style={{cursor :"pointer"}}><h3>
                                <img
                                    src={`assets/images/svgs/arrow-left.svg`}
                                    width="24"
                                    height="24"
                                    className="el-image"
                                    alt=""
                                    uk-svg=""
                                    hidden=""
                                />
                            </h3></Link>
                        </h2>
                        <table className="uk-table uk-table-striped">
                            <thead>
                                <th>{ languageStatus === 'en' ? 'S.No' : 'क्रम संख्या'}</th>
                                <th>{ languageStatus === 'en' ? 'Document Name' : 'कागजातको नाम'}</th>
                                <th>{ languageStatus === 'en' ? 'Download' : 'डाउनलोड'}</th>
                            </thead>
                            <tbody>
                                {downloadDocuments.length > 0
                                    ? downloadDocuments.filter((a) => {
                                        return a.aboutUsId === detailId
                                    }).map((data, index) => {
                                        return (
                                            data.documentWithNameList.length > 0 ?
                                                (data.documentWithNameList).map((image, imageIndex) => {
                                                        return (
                                                            <>
                                                <tr key={index}>
                                    <th>{languageStatus === 'en' ? imageIndex + 1 : engToNepNumber(imageIndex + 1)}</th>
                                    <td>{languageStatus === 'en' ? (image.docName ?? image.docNameNp ?? 'N/A') : (image.docNameNp ?? image.docName ?? 'नाम भेटियन')}</td>
                                    <td>
                                        <a
                                            href={image.docUrl} target="_blank"
                                            style={{
                                                fontSize: "20px",
                                            }}
                                            download={image.docUrl}
                                        >
                                                  <img
                                                      src={`assets/images/svgs/download.svg`}
                                                      width="24"
                                                      height="24"
                                                      className="el-image"
                                                      alt=""
                                                      uk-svg=""
                                                      hidden=""
                                                  />
                                              </a>
                                    </td>
                                  </tr>
                                            </>
                                                        )
                                                    }) : null
                                        );
                                    })
                                    : <tr>
                                      <td
                                          colSpan={4}
                                          className="uk-text-bold"
                                          style={{fontSize: '20px'}}
                                      >
                                          { languageStatus === 'en' ? `No File Available for Download` : `डाउनलोड फाइल भेटियन`}...
                                      </td>
                                    </tr>}
                            </tbody>
                        </table>
            </div>
            </div>
    </>
    );
}

export default DownloadDetails;
