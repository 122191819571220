function EngToNepAccountProfile(strMonth) {
    let profiles = {
        "Assets": "सम्पत्ति",
        "Liabilities": "दायित्व",
        "Equity": "इक्विटी",
        "Revenue": "आय",
        "Expenses": "खर्च",
    };

    let arrNumNe = strMonth.split(' ').map(function (ch) {
        return profiles[ch];
    });
    return arrNumNe.join('');
}

export default EngToNepAccountProfile;
