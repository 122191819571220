import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    organizationId:null,
};

const organization = createSlice({
    name:'organization',
    initialState,
    reducers:{
        setOrganizationId: (state,action) => {
            return {
                ...state,
                organizationId:action.payload
            }
        }
    }
})

export const {setOrganizationId} = organization.actions;

export default organization.reducer;