import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import SpriteLogo from '../../partial/svgs/sprite.svg';
import {toast} from "react-toastify";
import ApiService from "../../../network/ApiService";
import engToNepNumber from "../../../helpers/EngToNepNumber";
import engToNepMonth from "../../../helpers/EngToNepMonth";

function TariffCalculator(props) {
    const {tariffChecker, allTariffDetails, organizationBasicDetail} = ApiService();
    const {orgDetailData} = useSelector((state) => state.contact);
    const {organizationId} = useSelector((state) => state.organization);
    const [orgDetail, setOrgDetail] = useState({});
    const [totalAmount, setTotalAmount] = useState(0);
    const [tariffRangeList, setTariffRangeList] = useState([]);
    const [showResults, setShowResults] = useState(false)
    const [showToUnit, setShowToUnit] = useState(false)
    const [showTariffButton, setShowTariffButton] = useState(false)
    const [showTariffRangeButton, setShowTariffRangeButton] = useState(false)

    // const [customerSelected, setCustomerSelected] = useState('yes');
    // const [tapSizeSelected, setTapSizeSelected] = useState('yes');
    //
    // const handleChangeCustomer = event => {
    //     console.log(event.target.value);
    //     setCustomerSelected(event.target.value);
    // };
    //
    // const handleChangeTapSize = event => {
    //     console.log(event.target.value);
    //     setTapSizeSelected(event.target.value);
    // };

    const [inputData, setInputData] = useState({
        orgId: organizationId,
        customerTypeId: "",
        tapSizeId: "",
        fromUnit: "",
        toUnit: "",
    });

    const handleChange = (e) => {
        const list = {...inputData};
        if (e.target) {
            const {name, value} = e.target;
            list[name] = value;
        }
        if (list['customerTypeId'] !== '' && list['tapSizeId']) {
            setShowResults(true)
        } else {
            setShowResults(false)
        }

        if (e.target.name === 'calculateTariff') {
            const {value} = e.target;
            if (value === 'tariffRange') {
                setShowToUnit(true)
                setShowTariffButton(false)
                setShowTariffRangeButton(true)
            } else {
                setShowToUnit(false)
                setShowTariffRangeButton(false)
                setShowTariffButton(true)
            }
        }
        setInputData(list);
    };

    const [tariffList, setTariffList] = useState([]);
    const [customerTypeList, setCustomerTypeList] = useState([]);
    const [tabSizeList, setTabSizeList] = useState([]);

    const getCustomerTypeList = async () => {
        try {
            const response = await organizationBasicDetail();
            setCustomerTypeList(response.data.customerType);
        } catch (error) {
            console.log(error.message);
        }
    };
    const getTabSizeList = async () => {
        try {
            const response = await organizationBasicDetail();
            setTabSizeList(response.data.tapSize);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getTariffList = async () => {
        try {
            const response = await allTariffDetails();
            setTariffList(response.data.allTariffParams);
        } catch (error) {
            console.log(error.message);
        }
    };

    const calculateTariff = async () => {
        try {
            if (inputData.customerTypeId === '') {
                props.language === 'en' ?
                    toast.error("Customer Type is Required")
                    : toast.error("ग्राहक प्रकार आवश्यक छ");
                return;
            } else if (inputData.tapSizeId === '') {
                props.language === 'en' ?
                    toast.error("Tap Size is Required")
                    : toast.error("ट्याप साइज आवश्यक छ");
                return;
            } else if (inputData.fromUnit === '') {
                props.language === 'en' ?
                    toast.error("Consume Unit is Required")
                    : toast.error("उपभोग एकाइ आवश्यक छ");
                return;
            }
            const response = await tariffChecker(inputData.customerTypeId, inputData.tapSizeId, inputData.fromUnit, inputData.fromUnit);
            setTariffRangeList([]);
            setTotalAmount(response.data[0].tariffAmounts);
            // setInputData({
            //     orgId: organizationId,
            //     customerTypeId: "",
            //     tapSizeId: "",
            //     fromUnit: "",
            //     toUnit: ""
            // });
            props.language === 'en' ?
                toast.success("Tariff Amount Loaded Successfully")
                : toast.success("महशुल रकम सफलतापूर्वक लोड भयो");
        } catch (error) {
            if (error.response.status === 404) {
                props.language === 'en' ?
                    toast.error("Tariff Setup Not Found")
                    : toast.error('महशुल सेटअप फेला परेन');
            } else {
                props.language === 'en' ?
                    toast.error("Something Went Wrong")
                    : toast.error('केहि गलत भयो');
            }
        }
    };


    const calculateTariffRange = async () => {
        try {
            if (inputData.customerTypeId === '') {
                props.language === 'en' ?
                    toast.error("Customer Type is Required")
                    : toast.error("ग्राहक प्रकार आवश्यक छ");
                return;
            } else if (inputData.tapSizeId === '') {
                props.language === 'en' ?
                    toast.error("Tap Size is Required")
                    : toast.error("ट्याप साइज आवश्यक छ");
                return;
            } else if (inputData.fromUnit === '') {
                props.language === 'en' ?
                    toast.error("From Unit is Required")
                    : toast.error("युनिट देखी एकाइ आवश्यक छ");
                return;
            } else if (inputData.toUnit === '') {
                props.language === 'en' ?
                    toast.error("To Unit is Required")
                    : toast.error("युनिट सम्म एकाइ आवश्यक छ");
                return;
            }
            const response = await tariffChecker(inputData.customerTypeId, inputData.tapSizeId, inputData.fromUnit, inputData.toUnit);
            setTotalAmount(0);
            setTariffRangeList(response.data);
            // setTotalAmount(response.data[0].tariffAmounts);
            // setInputData({
            //     orgId: organizationId,
            //     customerTypeId: "",
            //     tapSizeId: "",
            //     fromUnit: "",
            //     toUnit: ""
            // });
            props.language === 'en' ?
                toast.success("Tariff Range Loaded Successfully")
                : toast.success("महशुल दायरा गणना सफलतापूर्वक लोड भयो");
        } catch (error) {
            if (error.response.status === 404) {
                props.language === 'en' ?
                    toast.error("Tariff Setup Not Found")
                    : toast.error('महशुल सेटअप फेला परेन');
            } else {
                props.language === 'en' ?
                    toast.error("Something Went Wrong")
                    : toast.error('केहि गलत भयो');
            }
        }
    };

    useEffect(() => {
        if (orgDetailData) {
            setOrgDetail(orgDetailData);
            getTariffList();
            getCustomerTypeList();
            getTabSizeList();
        }
    }, [orgDetailData]);

    return (
        <>
            <div
                id="tariff_calculator"
                className="uk-section-default uk-section uk-section-large uk-padding-remove-top"
                uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-bottom-medium; delay: false;"
            >
            <div className="uk-container">
                <div className="tm-grid-expand">
                    <h2 className="uk-h2 uk-text-center">
                        <span className="underline-secondary">
                            {props.language === 'en' ?  'Tariff Calculator' : `महशुल क्याल्कुलेटर`}
                        </span>
                    </h2>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="uk-container uk-margin-large">
                        <div className="tm-grid-expand uk-grid-margin uk-grid uk-grid-divider" uk-grid="true">
                            <div className="uk-width-2-3@m">
                                <div>
                                    <h3 className="uk-h3">
                                        {props.language === 'en' ?  'Please Select Customer Type' : `कृपया ग्राहक प्रकार चयन गर्नुहोस्`}
                                    </h3>
                                    <ul className="uk-child-width-1-2 uk-child-width-1-4@m uk-grid">
                                    {customerTypeList.length > 0 ?
                                        customerTypeList.map((data, index) => {
                                            return (
                                                <li className="filter-checkbox" key={`customer_type_list_` + index}>
                                                        <label className="filter-checkbox_label">
                                                            <input
                                                                type="radio"
                                                                className="uk-checkbox filter-checkbox_input"
                                                                name="customerTypeId"
                                                                value={data.id}
                                                                onChange={(e) => handleChange(e)}
                                                            />
                                                            <span className="filter-checkbox_item uk-flex-inline uk-flex-middle">
                                                                <span className="filter-checkbox_box uk-margin-small-right">
                                                                    <svg className="filter-checkbox_box_icon">
                                                                        <use xlinkHref={SpriteLogo + `#icon-check`}></use>
                                                                    </svg>
                                                                </span>
                                                                <span>
                                                                    {props.language === 'en' ?  (data.displayValue ?? data.displayValueNp) : data.displayValueNp}
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </li>
                                            )
                                        })
                                        : null}
                                </ul>
                                </div>
                                <div>
                                    <h3 className="uk-h3 uk-margin-small-top">
                                        {props.language === 'en' ?  'Please Select Tap Type' : `कृपया ट्याप साइज चयन गर्नुहोस्`}
                                    </h3>
                                    <ul className="uk-child-width-1-1 uk-child-width-1-4@m uk-grid">
                                    {tabSizeList.length > 0 ?
                                        <>
                                            {
                                                tabSizeList.map((data, index) => {
                                                    return (
                                                        <li className="filter-checkbox" key={index}>
                                                            <label className="filter-checkbox_label">
                                                                <input
                                                                    type="radio"
                                                                    className="uk-checkbox filter-checkbox_input"
                                                                    name="tapSizeId"
                                                                    onChange={(e) => handleChange(e)}
                                                                    value={data.id}
                                                                />
                                                                <span className="filter-checkbox_item uk-flex-inline uk-flex-middle">
                                                                    <span className="filter-checkbox_box uk-margin-small-right">
                                                                        <svg className="filter-checkbox_box_icon">
                                                                            <use xlinkHref={SpriteLogo + `#icon-check`}></use>
                                                                        </svg>
                                                                    </span>
                                                                    {props.language === 'en' ?  (data.displayValue ?? data.displayValueNp) : data.displayValueNp}
                                                                </span>
                                                            </label>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </>
                                        : null}
                                </ul>
                                </div>
                                <div className={showResults ? 'uk-grid-margin' : 'uk-hidden'}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <h3 className="uk-h3">
                                            {props.language === 'en' ?  'Please Select Calculation Type' : `कृपया गणना प्रकार चयन गर्नुहोस्`}
                                        </h3>
                                        <ul className="uk-child-width-1-1 uk-child-width-1-4@m uk-grid">
                                            <li className="filter-checkbox" key="tariffRange">
                                                <label className="filter-checkbox_label">
                                                    <input
                                                        type="radio"
                                                        className="uk-checkbox filter-checkbox_input"
                                                        name="calculateTariff"
                                                        onChange={(e) => handleChange(e)}
                                                        value="tariffRange"
                                                    />
                                                    <span className="filter-checkbox_item uk-flex-inline uk-flex-middle">
                                                        <span className="filter-checkbox_box uk-margin-small-right">
                                                            <svg className="filter-checkbox_box_icon">
                                                                <use xlinkHref={SpriteLogo + `#icon-check`}></use>
                                                            </svg>
                                                        </span>
                                                        {props.language === 'en' ?  `Tariff Range` : `महशुल दायरा`}
                                                    </span>
                                                </label>
                                            </li>
                                            <li className="filter-checkbox" key="tariff">
                                                <label className="filter-checkbox_label">
                                                    <input
                                                        type="radio"
                                                        className="uk-checkbox filter-checkbox_input"
                                                        name="calculateTariff"
                                                        onChange={(e) => handleChange(e)}
                                                        value="tariff"
                                                    />
                                                    <span className="filter-checkbox_item uk-flex-inline uk-flex-middle">
                                                        <span className="filter-checkbox_box uk-margin-small-right">
                                                            <svg className="filter-checkbox_box_icon">
                                                                <use xlinkHref={SpriteLogo + `#icon-check`}></use>
                                                            </svg>
                                                        </span>
                                                        {props.language === 'en' ?  `Tariff` : `महशुल`}
                                                    </span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div className="uk-flex uk-margin-small-top">
                                        <input
                                            type="text"
                                            name="fromUnit"
                                            className="uk-input"
                                            onChange={(e) => handleChange(e)}
                                            id="fromUnit"
                                            placeholder={showToUnit ? (props.language === 'en' ?  `From Unit` : `युनिट देखी`) : (props.language === 'en' ?  `Consume Unit` : `खपत युनिट`)}
                                        />
                                        <input
                                            type="text"
                                            name="toUnit"
                                            className={showToUnit ? 'uk-input uk-margin-left' : 'uk-hidden'}
                                            id="toUnit"
                                            onChange={(e) => handleChange(e)}
                                            placeholder={props.language === 'en' ?  `To Unit` : `युनिट सम्म`}
                                        />
                                    </div>
                                        <div className="uk-flex uk-margin">
                                        <button
                                            type={showTariffRangeButton ? `submit` : `button`}
                                            className={showTariffRangeButton ? 'uk-button-primary uk-button uk-text-uppercase uk-margin-small-right' : 'uk-hidden'}
                                            onClick={() => calculateTariffRange()}
                                        >
                                            {props.language === 'en' ?  `Calculate Range` : `दायरा गणना गर्नुहोस्`}
                                        </button>
                                        <button
                                            type={showTariffButton ? `submit` : `button`}
                                            className={showTariffButton ? 'uk-button-primary uk-button uk-text-uppercase' : 'uk-hidden'}
                                            onClick={() => calculateTariff()}
                                        >
                                            {props.language === 'en' ?  `Calculate` : `गणना गर्नुहोस्`}
                                        </button>
                                    </div>
                                        <div
                                            className="uk-margin uk-overflow-auto pre-scrollable"
                                            style={{maxHeight: '200px'}}
                                        >
                                    {
                                        tariffRangeList.length === 0 ?
                                            <>
                                                <div className="uk-flex uk-margin">
                                                    {props.language === 'en' ?  `Total Rs.` : `कुल रु.`}
                                                    &nbsp; <label>
                                                    {props.language === 'en' ? totalAmount : engToNepNumber(totalAmount)}</label>
                                                </div>
                                            </> : null
                                    }
                                            {
                                                tariffRangeList.length > 0 ?
                                                    tariffRangeList.map((data, index) => {
                                                        return (
                                                            <>
                                                        <ul className="uk-list uk-list-collapse">
                                                            <li key={`consumed_unit_` + index}>
                                                                {props.language === 'en' ?
                                                                    <>
                                                                        For {data.consumedUnits} Units, Total Cost is <strong>Rs. {data.tariffAmounts}</strong>
                                                                    </>
                                                                        : <>
                                                                    {engToNepNumber(data.consumedUnits)} युनिट को लागी शुल्क <strong> रु. {engToNepNumber(data.tariffAmounts)} </strong>
                                                                    </>
                                                                }

                                                            </li>
                                                        </ul>
                                                    </>
                                                        )
                                                    }) :
                                                    <>
                                            </>
                                            }
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="uk-width-1-3@m uk-overflow-auto"
                                id="searchList"
                                style={{maxHeight: '40rem'}}
                            >
                                {
                                    tariffList.length > 0 ?
                                        tariffList.map((data, index) => {
                                            return (
                                                <span key={`tariff_list_` + index}>
                                                    <h3
                                                        className="uk-h3"
                                                        key={`top_heading_` + index}
                                                    >
                                                        {props.language === 'en' ?
                                                            <>
                                                                Tariff For {data.month} - {data.year}
                                                            </>
                                                            : <>
                                                            {engToNepMonth(data.month)} - {engToNepNumber(data.year)} को लागी शुल्क
                                                            </> }
                                                    </h3>
                                                    {
                                                        (data.customerTypeAndTapSize).map((tariffData, tariffIndex) => {
                                                            return (
                                                                <span key={`tap_size_` + tariffIndex}>
                                                                    <span
                                                                        className="uk-h5"
                                                                        key={`tap_heading_` + tariffIndex}
                                                                    >
                                                                        {props.language === 'en' ? tariffData.customerTypeAndTapSizeCombined : tariffData.customerTypeAndTapSizeCombinedNp }
                                                                    </span>
                                                                    <ul
                                                                        className="uk-list uk-list-collapse"
                                                                        key={`all_tariff` + tariffIndex}
                                                                    >
                                                                        {
                                                                            (tariffData.allTariffDetails).map((tariffDetails, tariffDetailIndex) => {
                                                                                return (
                                                                                    <li key={tariffDetailIndex}>
                                                                                        {
                                                                                            props.language === 'en' ? (tariffDetails.description) :
                                                                                                <>
                                                                                                    {engToNepNumber(tariffDetails.fromUnit)} देखि {engToNepNumber(tariffDetails.toUnit)} सम्म : रु. {tariffDetails.minimumPrice > 0 ? (engToNepNumber(tariffDetails.minimumPrice) + ` न्यूनतम रकम`) : (engToNepNumber(tariffDetails.perUnitPrice) + ` प्रति युनिट`) }
                                                                                                </>
                                                                                        }
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </span>
                                                            )
                                                        })}
                                                </span>
                                            )
                                        })
                                        :
                                        <>
                                            {
                                                props.language === 'en' ? 'Tariff Setup Not Found' : `कुनै महशुल फेला परेन`
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
      </>
    );
}

export default TariffCalculator;
