import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiService from "../../../../network/ApiService";

function ScheduleDetail() {
  const { organizationId } = useSelector((state) => state.organization);
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
    fetchServices,
    fetchScheduleList,
    fetchScheduleById,
  } = ApiService();

  const [scheduleList, setScheduleList] = useState([]);
  const params = useParams();
  const scheduleId = params.waterScheduleId;

  const getScheduleById = async () => {
    try {
      const response = await fetchScheduleById(scheduleId);
      setScheduleList(response.data.resultList);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (organizationId && scheduleId) {
      getScheduleById();
    }
  }, [organizationId, scheduleId]);

  return (
    <section className="ftco-section ftco-no-pb ftco-no-pt mt-3">
      <div className="container">
        <div className="page-heading">
          <h3 className="heading-text">पानी बितरण तालिका</h3>
        </div>
        <div className="row">
          {scheduleList.length > 0
            ? scheduleList.map((data, index) => {
                return (
                  <div class="col-md-4 mb-5" key={index}>
                    {data.scheduleDetailList.length > 0 ? (
                      <div className="card">
                        <div className="card-header water-schedule-detail-card-header-one">
                          <div className="d-flex">
                            {/* <div className="">Source</div> */}
                            <div className="text-center">{data.secSource}</div>
                          </div>
                        </div>
                        <div className="card-header water-schedule-detail-card-header-two">
                          <div className="d-flex">
                            {/* <div className="">Day Part</div> */}
                            <div className="text-center">{data.dayPart}</div>
                          </div>
                        </div>
                        <div className="card-body water-schedule-detail">
                          <table className="table table-striped water-schedule-detail-table">
                            <tbody>
                              {data.scheduleDetailList.map((schedule, key) => {
                                return (
                                  <tr key={key}>
                                    <th>{schedule.weekDayName}</th>
                                    <td>{schedule.fromTime}</td>
                                    <td>{schedule.toTime}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </section>
  );
}

export default ScheduleDetail;
