import React from 'react'
import {Link} from 'react-router-dom'

function NotFoundPage() {
    const languageStatus = sessionStorage.getItem("language");
    return (
        <div className="tm-page">
      <div className="uk-section uk-section-large" uk-height-viewport="false">
            <div className="uk-container uk-container-default uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                <div className="uk-text-center">
                    <img
                        src={`assets/images/svgs/not-found.svg`}
                        alt=""
                        className="uk-margin-bottom"
                        style={{width: "250px"}}
                    />
                    <div>
                        <h2 className="uk-h2 uk-margin">
                            {languageStatus === 'en' ? `Sorry, Page not found`: `माफ गर्नुहोस्, पृष्ठ फेला परेन`}
                        </h2>
                        <Link to="/" className="uk-link-text">{languageStatus === 'en' ? `Return to Homepage` : `गृहपृष्ठ मा फर्कनुहोस्`}</Link>
                    </div>
                </div>
            </div>
        </div>
      </div>

    )
}

export default NotFoundPage
