import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiService from "../../../network/ApiService";
import Service from "./Service";

function ServiceDetail() {
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
    fetchMessages,
    fetchNewsDetailById,
    fetchServiceDetailById,
  } = ApiService();

  const { organizationId } = useSelector((state) => state.organization);
  const [serviceDetail, setServiceDetail] = useState([]);
  const params = useParams();
  const serviceId = params.serviceId;

  const getServiceDetails = async () => {
    try {
      const response = await fetchServiceDetailById(serviceId);
      setServiceDetail(response.data.messageWithImg);
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId && serviceId) {
      getServiceDetails();
    }
  }, [organizationId, serviceId]);

  return (
    <section className="ftco-section ftco-no-pb ftco-no-pt mt-3">
      <div className="container">
        <div className="page-heading"></div>
        <div className="row no-gutters">
          <div className="col-md-8 d-flex align-items-stretch">
            {serviceDetail.length > 0
              ? serviceDetail.map((data, index) => {
                  return (
                    <div className="single-post" key={index}>
                      <h1>{data.header}</h1>
                      <div className="mb-5 blogImage">
                        <img
                          src={
                            data.imageUrl != "n/a"
                              ? data.imageUrl
                              : `assets/images/placeholder.jpg`
                          }
                          alt=""
                        />
                      </div>
                      <div
                        dangerouslySetInnerHTML={parseHtmlToView(data.message)}
                      ></div>
                    </div>
                  );
                })
              : null}
          </div>
          <div className="col-md-4 wrap-about">
            <Service serviceDetails={true} serviceDetailId={serviceId} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceDetail;
