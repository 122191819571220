function EngToNepMonth(strMonth) {
    let months = {
        "Baisakh" : "बैशाख",
        "Jestha" : "जेठ",
        "Ashadh" : "असार",
        "Shrawan" : "श्रावण",
        "Bhadra" : "भाद्र",
        "Ashwin" : "आश्विन",
        "Kartik" : "कार्तिक",
        "Mangsir" : "मंसिर",
        "Poush" : "पुष",
        "Magh" : "माघ",
        "Falgun" : "फाल्गुन",
        "Chaitra" : "चैत्र"
    };

    let arrNumNe = strMonth.split(' ').map(function (ch) {
        return months[ch];
    });
    return arrNumNe.join('');
}

export default EngToNepMonth;
