import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ApiService from "../network/ApiService";
import Popup from "./includes/Popup";
import About from "./pages/About";
import PaymentGateway from "./pages/eservices/paymentGateway/PaymentGateway";
import Hero from "./pages/Hero";
import Eservice from "./pages/homepages/Eservice";
import Newsslides from "./pages/homepages/Newsslides";
import Serviceslides from "./pages/homepages/Serviceslides";
import News from "./pages/news/News";
import Profile from "./pages/homepages/Profile";
import TariffCalculator from "./pages/homepages/TariffCalculator";
import AppSection from "./pages/homepages/AppSection";
import Notices from "./pages/notices/Notices";
import FixedButtons from "./partial/FixedButtons";

function Home() {
  const [showModal, setShowModal] = useState(true);
  const { organizationId } = useSelector((state) => state.organization);
  const languageStatus = sessionStorage.getItem("language");
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
  } = ApiService();

  const [newsList, setNewsList] = useState([]);
  const getNewsList = async () => {
    try {
      const response = await fetchNewsUpdates();
      setNewsList(response.data.aboutUsNewsAndImg);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (organizationId) {
      getNewsList();
    }
  }, [organizationId]);
  const handleShowModal = (showHide) => {
    setShowModal(showHide);
  };

  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    const popupStatus = sessionStorage.getItem("popup");
    if (!popupStatus) {
      setPopupVisible(true);
      sessionStorage.setItem("popup", 1);
    }
  }, []);

  return (
    <div className="tm-page">
      <Newsslides newsList={newsList.length > 0 ? newsList : []} language={languageStatus}/>
      {/*<Hero language={languageStatus}/>*/}
      <Serviceslides language={languageStatus}/>
      <About language={languageStatus}/>
      <Eservice language={languageStatus}/>
      <Profile language={languageStatus}/>
      <TariffCalculator language={languageStatus}/>
      <AppSection language={languageStatus}/>
      <PaymentGateway language={languageStatus}/>
      <div
          className="uk-section-muted section-news-notice uk-section uk-position-relative"
          // uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-bottom-medium; delay: false;"
      >
        <div className="uk-container">
            <div className="uk-grid-margin uk-container uk-container-small">
                <div className="tm-grid-expand uk-grid" uk-grid="true">
                      <News newsList={newsList.length > 0 ? newsList : []} language={languageStatus} />
                      <Notices newsList={newsList.length > 0 ? newsList : []} language={languageStatus} />
                </div>
            </div>
        </div>
        <div className="right uk-position-absolute"></div>
      </div>
      {popupVisible ? (
          <>
         {/*<div className="d-flex justify-content-between">*/}
         {/*  <div>*/}
            <Popup
              showModal={showModal}
              onClose={() => {
                handleShowModal(false);
              }}
            />
         {/*  </div>*/}
         {/*</div>*/}
         </>
      ) : null }
    </div>
  );
}

export default Home;
