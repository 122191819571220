import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../network/ApiService";
import engToNepNumber from "../../helpers/EngToNepNumber";
import DownloadDetails from "./DownloadDetails";
import {Link} from "react-router-dom";

function Download() {
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchDownloads,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const [downloadDocuments, setDownloadDocuments] = useState([]);
    const [aboutUsId, setAboutUsId] = useState(null);
    const languageStatus = sessionStorage.getItem("language");
    const getDownloadDocuments = async () => {
        try {
            const response = await fetchDownloads();
            setDownloadDocuments(response.data.messageWithImg);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (organizationId) {
            getDownloadDocuments();
        }
    }, [organizationId]);

    return (
        <>
      {/*<section className="ftco-section ftco-no-pb ftco-no-pt mt-3">*/}
            {/*  <div className="container">*/}
            {/*    <div className="page-heading">*/}
            {/*      <h3 className="heading-text">डाउनलोड</h3>*/}
            {/*    </div>*/}
            {/*    <div className="row no-gutters">*/}
            {/*      <div className="col-md-12 d-flex align-items-stretch">*/}
            {/*        <table className="table table-striped">*/}
            {/*          <tbody>*/}
            {/*            {downloadDocuments.length > 0*/}
            {/*              ? downloadDocuments.map((data, index) => {*/}
            {/*                  return (*/}
            {/*                    <tr key={index}>*/}
            {/*                      <th>{index + 1}</th>*/}
            {/*                      <td>{data.header}</td>*/}
            {/*                      <td><a href={data.imageUrl} target="_blank" download><i className="fa fa-sharp fa-solid fa-download"></i></a></td>*/}
            {/*                    </tr>*/}
            {/*                  );*/}
            {/*                })*/}
            {/*              : null}*/}
            {/*          </tbody>*/}
            {/*        </table>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-2 d-flex align-items-stretch">*/}
            {/*         /!* <News newsDetails={true} />  *!/*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</section>*/}
            <div className="uk-section uk-section-default section-contact">
            <div className="uk-container">
                { aboutUsId === null ?
                    <>
                        <h2
                            className="uk-h2 uk-text-center uk-margin-auto uk-scrollspy-inview uk-margin-large"
                            uk-scrollspy-class=""
                        >
                            <span className="underline-secondary">
                                { languageStatus === 'en' ? `Download` : `डाउनलोड`}
                            </span>
                        </h2>
                        <table className="uk-table uk-table-striped">
                            <thead>
                        <th>{ languageStatus === 'en' ? 'S.No' : 'क्रम संख्या'}</th>
                        <th>{ languageStatus === 'en' ? 'Document Name' : 'कागजातको नाम'}</th>
                        <th>{ languageStatus === 'en' ? 'View' : 'अवलोकन'}</th>
                    </thead>
                    <tbody>
                        {downloadDocuments.length > 0
                            ? downloadDocuments.map((data, index) => {
                                return (
                                    <tr key={index}>
                            <th>{languageStatus === 'en' ? index + 1 : engToNepNumber(index + 1)}</th>
                            <td>{languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header)}</td>
                            <td>
                                {data.documentWithNameList.length > 0 ?
                                    <>
                                    <Link onClick={() => setAboutUsId(data.aboutUsId)}
                                          style={{
                                              fontSize: "20px",
                                              cursor: "pointer"
                                          }}>
                                      <img
                                          src={`assets/images/svgs/eye.svg`}
                                          width="24"
                                          height="24"
                                          className="el-image"
                                          alt=""
                                          uk-svg=""
                                          hidden=""
                                      />
                                    </Link>

                                    </>
                                    :
                                    <div style={{fontStyle: 'italic'}}>
                                        { languageStatus === 'en' ? `No File Available for Download` : `डाउनलोड फाइल भेटियन`}...
                                    </div>
                                }
                            </td>
                          </tr>
                                );
                            })
                            : <tr>
                              <td
                                  colSpan={4}
                                  className="uk-text-bold"
                                  style={{fontSize: '20px'}}
                              >
                                  { languageStatus === 'en' ? `No File Available for Download` : `डाउनलोड फाइल भेटियन`}...
                              </td>
                            </tr>}
                    </tbody>
                </table>
                    </> : <DownloadDetails detailId={aboutUsId} setAboutUsId={setAboutUsId}/>
                }
            </div>
        </div>
    </>
    );
}

export default Download;
