import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../../network/ApiService";

import "swiper/css";
import "swiper/css/bundle";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectCube} from "swiper";

function Serviceslides() {
    const {organizationId} = useSelector((state) => state.organization);
    const [sliderImages, setSliderImages] = useState([]);
    const {
        fetchSliderImages,
    } = ApiService();

    const getSliderImages = async () => {
        try {
            const response = await fetchSliderImages();
            setSliderImages(response.data.imagesList);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (organizationId) {
            getSliderImages();
        }
    }, [organizationId]);

    return (
        <>
        <div className="side-nav">
            {sliderImages.length > 0 ? (
                <div className="uk-container mySwiper">
              <Swiper
                  style={{transition: 'transform 0.3s ease'}}
                  modules={[EffectCube, Autoplay]} effect="cube" autoplay={true} loop={true} grabCursor={true}
                  cubeEffect={{
                      shadow: false,
                  }}
              >
                  {sliderImages.length > 0
                      ? sliderImages.map((data, index) => {
                          return (
                              <SwiperSlide key={index}>
                                  <img
                                      src={data ?? `assets/images/image.jpg`}
                                      alt={`slider${index + 1}`}
                                  />
                              </SwiperSlide>
                          );
                      })
                      : null}
              </Swiper>
          </div>
            ) : null}
      </div>
    </>
    );
}

export default Serviceslides;
