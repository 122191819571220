import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../../../network/ApiService";
import "./Schedule.css";
import engToNepNumber from "../../../../helpers/EngToNepNumber";
import engToNepDay from "../../../../helpers/EngToNepDay";
import engToNepTimeOfDay from "../../../../helpers/EngToNepTimeOfDay";

function ScheduleList() {
    const {organizationId} = useSelector((state) => state.organization);
    let scheduleListKey = '';
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchServices,
        fetchScheduleList,
    } = ApiService();

    const [scheduleList, setScheduleList] = useState([]);
    const languageStatus = sessionStorage.getItem("language");
    const getSchedule = async () => {
        try {
            const response = await fetchScheduleList();
            setScheduleList(response.data.masterList);
            setActiveArray(response.data.masterList[0]);
        } catch (error) {
            console.log(error.message);
        }
    };

    const [activeArray, setActiveArray] = React.useState(null);
    const getActiveTab = (index) => {
        setActiveArray(scheduleList[index])
    }

    useEffect(() => {
        if (organizationId) {
            getSchedule();
        }
    }, [organizationId]);
    return (
        <>
        {/*<div className="uk-section uk-section-default section-contact">*/}
            {/*    <div className="uk-container">*/}
            {/*        <h2*/}
            {/*            className="uk-h2 uk-text-center uk-margin-auto uk-scrollspy-inview uk-margin-large"*/}
            {/*            uk-scrollspy-class=""*/}
            {/*        >*/}
            {/*            <span className="underline-secondary">*/}
            {/*                {languageStatus === 'en' ? `Water Schedule List` : `पानी बितरण तालिका`}*/}
            {/*            </span>*/}
            {/*        </h2>*/}
            {/*        <table className="uk-table uk-table-striped">*/}
            {/*            <thead>*/}
            {/*                <tr>*/}
            {/*                    <th>#</th>*/}
            {/*                    <th>{languageStatus === 'en' ? `Schedule` : `तालिका`}</th>*/}
            {/*                    <th>{languageStatus === 'en' ? `No. of Weeks` : `हप्ता संख्या`}</th>*/}
            {/*                    <th>{languageStatus === 'en' ? `Details` : `विवरण`}</th>*/}
            {/*                </tr>*/}
            {/*            </thead>*/}
            {/*            <tbody>*/}
            {/*                {scheduleList.length > 0*/}
            {/*                    ? scheduleList.map((data, index) => {*/}
            {/*                        return (*/}
            {/*                            <tr key={index}>*/}
            {/*                    <th>{index + 1}</th>*/}
            {/*                    <td>{languageStatus === 'en' ? (data.scheduleName ?? data.scheduleNameNp) : (data.scheduleNameNp ?? data.scheduleName)}</td>*/}
            {/*                    <td>{languageStatus === 'en' ? data.weekCount : engToNepNumber(data.weekCount)}</td>*/}
            {/*                    <td>*/}
            {/*                      <Link to={`/water-schedule-detail/${data.id}`}>*/}
            {/*                        <i className="fa fa-solid fa-eye"></i>*/}
            {/*                      </Link>*/}
            {/*                    </td>*/}
            {/*                  </tr>*/}
            {/*                        );*/}
            {/*                    })*/}
            {/*                    : <tr>*/}
            {/*                      <td*/}
            {/*                          colSpan={4}*/}
            {/*                          className="uk-text-bold"*/}
            {/*                          style={{fontSize: '20px'}}*/}
            {/*                      >*/}
            {/*                          {languageStatus === 'en' ? 'Water Schedule Not Found' : `पानी बितरण तालिका भेटियन`}...*/}
            {/*                      </td>*/}
            {/*                    </tr>}*/}
            {/*            </tbody>*/}
            {/*        </table>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {scheduleList ?
                <>
                <div className="tm-page">
                    <div className="uk-section uk-section-default">
                        <div className="uk-container schedule_table">
                            <div className="tm-grid-expand">
                                <h2 className="uk-h2 uk-margin-large uk-text-center">
                                    <span className="underline-secondary">
                                        {languageStatus === 'en' ? `Water Distribution Schedule` : `पानी बितरण तालिका`}
                                    </span>
                                </h2>
                            </div>
                            {console.log(scheduleList)}
                            <div className={scheduleList.length > 0 ? `uk-grid` : ''}>
                                <div className="uk-width-1-5@m">
                                    <div
                                        className="uk-tab-left schedule_table_tab"
                                        uk-tab={`connect: #component-tab-left; animation: uk-animation-fade`}
                                    >
                                        {
                                            scheduleList.map((data, index) => {
                                                return (
                                                    <div key={`link_div_` + index}>
                                                        <a
                                                            href="#"
                                                            key={index}
                                                            onClick={() => getActiveTab(index)}
                                                        >
                                                            {languageStatus === 'en' ? (data.scheduleName ?? data.scheduleNameNp) : (data.scheduleNameNp ?? data.scheduleName)}
                                                            &nbsp; ( {languageStatus === 'en' ? (data.weekCount) : engToNepNumber(data.weekCount)} )
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <>
                                    { activeArray ?
                                        <>
                                            <div className="uk-width-expand@m uk-text-small" key={`main_list_` + activeArray.id}>
                                                <div id="component-tab-left">
                                                    <div className={scheduleList.length > 0 ? `uk-grid` : ''}>
                                                        <div className="uk-width-expand@m" key={`main_heading_`+activeArray.id}>
                                                    <div id="component-tab-left">
                                                        <div uk-grid="true">
                                                            <div className="uk-width-expand@m">
                                                                <div
                                                                    className="uk-card uk-card-default uk-card-small uk-margin-remove-first-child uk-margin"
                                                                    data-id="page#7-0-1-1"
                                                                >
                                                                    <div className="uk-card-body uk-margin-remove-first-child" key={activeArray.id}>
                                                                        <h3 className="el-title uk-card-title uk-margin-top uk-margin-remove-bottom">
                                                                          {
                                                                              activeArray && Object.keys(activeArray.scheduleDetailList).length > 0 ?
                                                                                  Object.keys(activeArray.scheduleDetailList).map((lastData, lastKey) => {
                                                                                  return (
                                                                                        <>
                                                                                            {
                                                                                                lastData ?
                                                                                                    <table className="uk-table uk-table-small uk-table-divider">
                                                                                                            <tbody>
                                                                                                            <tr key={`source_` + lastKey}>
                                                                                                                <th>
                                                                                                                    {languageStatus === 'en' ? `Source` : 'श्रोत'}
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    {
                                                                                                                        languageStatus === 'en' ?
                                                                                                                            (activeArray.scheduleDetailList[lastData][lastKey]['secSource'] ?? activeArray.scheduleDetailList[lastData][lastKey]['secSourceNp'])
                                                                                                                            : (activeArray.scheduleDetailList[lastData][lastKey]['secSourceNp'] ?? activeArray.scheduleDetailList[lastData][lastKey]['secSource'])
                                                                                                                    }
                                                                                                                </th>
                                                                                                            </tr>
                                                                                                            <tr key={`day_part_` + lastKey}>
                                                                                                                <th>
                                                                                                                    {languageStatus === 'en' ? `Day Part` : `दिनको भाग`}
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    {
                                                                                                                        languageStatus === 'en' ? Object.keys(activeArray.scheduleDetailList)[lastKey]
                                                                                                                            : engToNepTimeOfDay(Object.keys(activeArray.scheduleDetailList)[lastKey])
                                                                                                                    }
                                                                                                                </th>
                                                                                                            </tr>
                                                                                                            {activeArray.scheduleDetailList[lastData].map((list, listIndex) => {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        {list.fromTime !== undefined || list.toTime !== undefined ?
                                                                                                                            <tr key={`list_details_` + listIndex}>
                                                                                                                                <td>{languageStatus === 'en' ? (list.weekDay ?? engToNepDay(list.weekDay)) : (engToNepDay(list.weekDay) ?? list.weekDay)}</td>
                                                                                                                                <td><strong>{languageStatus === 'en' ? list.fromTime + ' - ' + list.toTime : engToNepNumber(list.fromTime) + ' - ' + engToNepNumber(list.toTime)}</strong></td>
                                                                                                                            </tr>
                                                                                                                            : <></>
                                                                                                                        }
                                                                                                                        </>);
                                                                                                            }) }
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    : <>Hello</>
                                                                                            }
                                                                                        </>
                                                                                      )

                                                                          })
                                                                                  : <>
                                                                                      <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>
                                                                                          <div
                                                                                              className="uk-flex-wrap-bottom uk-text-bold"
                                                                                              style={{fontSize: '24px'}}
                                                                                          >
                                                                                              {languageStatus === 'en' ? `Schedule Not Found` : `बितरण तालिका भेटियन`}...
                                                                                          </div>
                                                                                      </div>
                                                                                  </>
                                                                          }
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>
                                              <div
                                                  className="uk-flex-wrap-bottom uk-text-bold"
                                                  style={{fontSize: '24px'}}
                                              >
                                                  {languageStatus === 'en' ? `Water Schedule Not Found` : `पानी बितरण तालिका भेटियन`}...
                                              </div>
                                            </div>
                                        </>
                                    }
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </>
                : null}
      </>
    );
}

export default ScheduleList;
