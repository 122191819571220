import {Link} from "react-router-dom";
function FixedButtons() {
    const languageStatus = sessionStorage.getItem("language");
    return (
        <>
        <ul className="uk-list side-nav-ul">
              <li className="side-nav-li">
                  <Link to="/contact">
                      <span>
                          <svg className="svg uk-margin-small-right svg-light">
                              <use xlinkHref={`assets/images/sprite.svg#icon-phone`} />
                          </svg>
                          {languageStatus === 'en' ? `Contact` : `सम्पर्क` }
                      </span>
                  </Link>
              </li>
              {/*<li className="side-nav-li">*/}
              {/*    <a href="#">*/}
              {/*        <span>*/}
              {/*            <svg className="svg uk-margin-small-right svg-light">*/}
              {/*                <use xlinkHref={`assets/images/sprite.svg#icon-logout`} />*/}
              {/*            </svg>*/}
              {/*            Logout*/}
              {/*        </span>*/}
              {/*    </a>*/}
              {/*</li>*/}
              <li className="side-nav-li">
                  <a href="/#tariff_calculator">
                      <span>
                          <svg className="svg uk-margin-small-right svg-light">
                              <use xlinkHref={`assets/images/sprite.svg#icon-calculator`} />
                          </svg>
                          {languageStatus === 'en' ? `Calculator` : `क्याल्कुलेटर` }
                      </span>
                  </a>
              </li>
          </ul>
        </>
    );
}

export default FixedButtons;
