import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCube, EffectFlip, Navigation, Pagination, Parallax, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import "./Hero.css";
import ApiService from "../../network/ApiService";
import { useSelector } from "react-redux";
// import "swiper/less/navigation";
// import "swiper/less/pagination";
// import { motion } from "framer-motion";

function Hero() {
  const { organizationId } = useSelector((state) => state.organization);
  const [sliderImages, setSliderImages] = useState([]);
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
  } = ApiService();

  const getSliderImages = async () => {
    try {
      const response = await fetchSliderImages();
      setSliderImages(response.data.imagesList);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (organizationId) {
      getSliderImages();
    }
  }, [organizationId]);


  return (
    <>
      {sliderImages.length > 0 ? (
        // <motion.div animate={{ rotate: 360}} transition={{ type: "spring" }}>
        <Swiper
          className="hero-wrap swiper"
          modules={[Navigation, Pagination, Scrollbar,Autoplay,EffectFlip]}
          // spaceBetween={50}
          // slidesPerView={3}
          navigation
          // pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          autoplay={{ delay:5000 }}
          flipEffect={{ slideShadows:false}}
        >
          {sliderImages.length > 0
            ? sliderImages.map((data, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="swiper-slide-width"
                    style={{
                      width: "300px",
                      // height:"300px",
                    }}
                  >
                    <img
                      className="swiper-img"
                      src={data}
                      alt={`slider${index + 1}`}
                    />
                    
                  <img
                      className="swiper-img-bg"
                      src={data}
                      alt={`slider${index + 1}`}
                    />
                  </SwiperSlide>
                );
              })
            : null}
        </Swiper>
      ) : // </motion.div>
      null}
    </>
  );
}

export default Hero;
