import {
    combineReducers,
    configureStore
  } from "@reduxjs/toolkit";
import OrganizationReducer from "./reducers/OrganizationReducer";
import storage from 'redux-persist/lib/storage';
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import ContactListReducers from "./reducers/ContactListReducers";
  
  const combinedReducer = combineReducers({
    organization: OrganizationReducer,
    contact: ContactListReducers,
  });
  
  const rootReducer = (state, action) => {
    if (action.type === "organization/onLogout") {
      // check for action type
      state = undefined;
    }
    return combinedReducer(state, action);
  };
  const persistConfig = {
    key: 'root',
    storage,
  }

  const persistedReducer = persistReducer(persistConfig,rootReducer);
  
  export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
  });
  
  export const persistor = persistStore(store)
//   export default store;
  