import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contactListData: null,
  orgDetailData: null,
};

const contact = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setContactListData: (state, action) => {
      return {
        ...initialState,
        contactListData: action.payload,
      };
    },
    setOrgDetailData: (state, action) => {
      return {
        ...initialState,
        orgDetailData: action.payload,
      };
    },
  },
});

export const { setContactListData, setOrgDetailData } = contact.actions;

export default contact.reducer;
