import React, {useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../../../network/ApiService";
import engToNepNumber from "../../../../helpers/EngToNepNumber";

function OutStandingReport() {
    const {organizationId} = useSelector((state) => state.organization);
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchServices,
        fetchScheduleList,
        fetchOutStanding,
    } = ApiService();

    const [OutStandingReport, setOutStandingReport] = useState({});
    const [customerNo, setCustomerNo] = useState("");
    const [messageDetail, setMessageDetail] = useState(null);
    const languageStatus = sessionStorage.getItem("language");
    const getOutStandingReport = async () => {
        try {
            const response = await fetchOutStanding(customerNo);
            setOutStandingReport(response.data);
            if (Object.keys(response.data).length === 0) {
                languageStatus === 'en' ? setMessageDetail("Customer Detail Not Found...") :
                setMessageDetail("ग्राहक विवरण भेटियन...");
            }else{
                languageStatus === 'en' ? setMessageDetail("Customer Detail Not Found...") :
                    setMessageDetail("ग्राहक विवरण भेटियन...");
            }
        } catch (error) {
            setOutStandingReport({});
            languageStatus === 'en' ? setMessageDetail("Customer Detail Not Found...") :
                setMessageDetail("ग्राहक विवरण भेटियन...");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (organizationId) {
            getOutStandingReport();
        }
    };

    // useEffect(() => {
    //   if (organizationId) {
    //     getOutStandingReport();
    //   }
    // }, [organizationId]);

    return (
        <>
        <div className="uk-section uk-section-default">
            <div className="uk-container">
                <div className="uk-container uk-container-xsmall">
                    <h2 className="uk-h2 uk-text-center uk-margin-large">
                        <span className="underline-secondary">
                            { languageStatus === 'en' ? `Outstanding Details` : `बक्यौता विवरण`}
                        </span>
                    </h2>
                    <div className="uk-flex uk-flex-center uk-margin">
                        <form onSubmit={(e) => handleSubmit(e)} className="uk-width-medium uk-flex uk-flex-center">
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    className="uk-input"
                                    placeholder={ languageStatus === 'en' ? `Customer No.` : `ग्राहकको कोड`}
                                    value={customerNo}
                                    onChange={(e) => setCustomerNo(e.target.value)}
                                />
                            </div>
                            <button className="uk-button uk-button-primary uk-margin-small-left">
                                { languageStatus === 'en' ? `Search` : `खोज्नुहोस`}
                            </button>
                        </form>
                    </div>
                    {Object.keys(OutStandingReport).length > 0 ? (
                    <div className="uk-flex uk-flex-center">
                        <div className="uk-width-xlarge uk-flex uk-flex-column uk-flex-middle uk-card uk-card-body uk-card-default">

                            <h3 className="uk-h3 uk-text-center">
                                { languageStatus === 'en' ? `Customer's Detail` : `ग्राहक विवरण`}
                            </h3>
                            <ul className="uk-list list-leader uk-margin-remove-top" id="page#4">
                                <li className="el-item">
                                    <div
                                        className="uk-child-width-expand uk-grid-small uk-flex-middle uk-grid"
                                        uk-grid=""
                                    >
                                        <div className="uk-width-medium uk-text-break uk-first-column">
                                            <div className="el-title uk-margin-remove uk-text-bold">
                                                { languageStatus === 'en' ? `Name` : `नाम `}:
                                            </div>
                                        </div>
                                        <div>
                                            <div className="el-meta">{languageStatus === 'en' ? OutStandingReport.fullName : OutStandingReport.fullNameNp}</div>
                                        </div>
                                    </div>
                                </li>
                                <li className="el-item">
                                    <div
                                        className="uk-child-width-expand uk-grid-small uk-flex-middle uk-grid"
                                        uk-grid=""
                                    >
                                        <div className="uk-width-medium uk-text-break uk-first-column">
                                            <div className="el-title uk-margin-remove uk-text-bold">
                                                { languageStatus === 'en' ? `Address` : `ठेगाना `}:
                                            </div>
                                        </div>
                                        <div>
                                            <div className="el-meta">{languageStatus === 'en' ? OutStandingReport.address : OutStandingReport.addressNp}</div>
                                        </div>
                                    </div>
                                </li>
                                <li className="el-item"
                                    // style={{
                                    //     backgroundColor: "blanchedalmond",
                                    //     paddingLeft: "4px"
                                    // }}
                                >
                                    <div
                                        className="uk-child-width-expand uk-grid-small uk-flex-middle uk-grid payable"
                                        uk-grid=""
                                    >
                                        <div className="uk-width-medium uk-text-break uk-first-column">
                                            <div className="el-title uk-margin-remove uk-text-bold">
                                                { languageStatus === 'en' ? `Amount to be Paid` : `जम्मा भुक्तानी गर्ने रकम` } :
                                            </div>
                                        </div>
                                        <div>
                                            <div className="el-meta">
                                                <strong>
                                                    {
                                                        languageStatus === 'en' ?
                                                            <>
                                                                Rs. { OutStandingReport.totalPayable }
                                                            </>
                                                            :
                                                            <>
                                                                रु. { engToNepNumber(OutStandingReport.totalPayable) }
                                                            </>
                                                    }
                                                </strong></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    ) : (
                        <div>
                            {messageDetail ? (
                                <>
                                    <div className="uk-flex uk-flex-center">
                                        <div className="uk-width-xlarge uk-flex uk-flex-column uk-flex-middle uk-card uk-card-body uk-card-default">
                                            <h3 className="uk-h3 uk-text-center">{ messageDetail }</h3>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    )}
                </div>
            </div>
        </div>
      </>
    );
}

export default OutStandingReport;
