import React from "react";
import "./Team.css";
import engToNepNumber from "../../../helpers/EngToNepNumber";

function MemberDetail({subHeaderItems}) {
    const languageStatus = sessionStorage.getItem("language");
    return (
        <>
    {/*<div className="accordion-item">*/}
            {/*  <h2 className="accordion-header" id={`flush-${subHeaderItems.id}`}>*/}
            {/*    <button*/}
            {/*      className="accordion-button collapsed"*/}
            {/*      type="button"*/}
            {/*      data-bs-toggle="collapse"*/}
            {/*      data-bs-target={`#flush-collapse${subHeaderItems.id}`}*/}
            {/*      aria-expanded="false"*/}
            {/*      aria-controls={`flush-collapse${subHeaderItems.id}`}*/}
            {/*    >*/}
            {/*      {subHeaderItems.nameNp}*/}
            {/*    </button>*/}
            {/*  </h2>*/}
            {/*  <div*/}
            {/*    id={`flush-collapse${subHeaderItems.id}`}*/}
            {/*    className="accordion-collapse collapse"*/}
            {/*    aria-labelledby={`flush-${subHeaderItems.id}`}*/}
            {/*    data-bs-parent="#accordionFlushExample"*/}
            {/*  >*/}
            {/*    <div className="accordion-body">*/}
            {/*      <div class="accordion row" id={`sub-accordion${subHeaderItems.id}`}>*/}
            {/*        {subHeaderItems.subHeaders && subHeaderItems.subHeaders.length > 0*/}
            {/*          ? subHeaderItems.subHeaders.map((data, key) => {*/}
            {/*              return (*/}
            {/*                <>*/}
            {/*                  {data.staffs ? (*/}
            {/*                    <>*/}
            {/*                      {data.staffs.length > 0 ? (*/}
            {/*                        <div className="accordion-item" key={key}>*/}
            {/*                          <h2*/}
            {/*                            className="accordion-header"*/}
            {/*                            id={`flush-${data.id}`}*/}
            {/*                          >*/}
            {/*                            <button*/}
            {/*                              className="accordion-button collapsed"*/}
            {/*                              type="button"*/}
            {/*                              data-bs-toggle="collapse"*/}
            {/*                              data-bs-target={`#flush-collapse${data.id}`}*/}
            {/*                              aria-expanded="false"*/}
            {/*                              aria-controls={`flush-collapse${data.id}`}*/}
            {/*                            >*/}
            {/*                              {data.nameNp}*/}
            {/*                            </button>*/}
            {/*                          </h2>*/}
            {/*                          <div*/}
            {/*                            id={`flush-collapse${data.id}`}*/}
            {/*                            className="accordion-collapse collapse"*/}
            {/*                            aria-labelledby={`flush-${data.id}`}*/}
            {/*                            data-bs-parent={`#sub-accordion${subHeaderItems.id}`}*/}
            {/*                          >*/}
            {/*                            <div className="row accordion-body">*/}
            {/*                              {data.staffs.map((staff, index) => {*/}
            {/*                                return (*/}
            {/*                                  <div*/}
            {/*                                    className="member col-md-3"*/}
            {/*                                    key={index}*/}
            {/*                                  >*/}
            {/*                                    <div className="member-img">*/}
            {/*                                      <img*/}
            {/*                                        src={*/}
            {/*                                          staff.photoUrl*/}
            {/*                                            ? staff.photoUrl*/}
            {/*                                            : "assets/images/avatar-placholder.png"*/}
            {/*                                        }*/}
            {/*                                        className="img-fluid"*/}
            {/*                                        alt=""*/}
            {/*                                      />*/}
            {/*                                    </div>*/}
            {/*                                    <div className="member-info">*/}
            {/*                                      <h4>{staff.nameNp}</h4>*/}
            {/*                                      {staff.staffDesignationNp ? (*/}
            {/*                                        <>*/}
            {/*                                          <span>*/}
            {/*                                            {staff.staffDesignationNp}*/}
            {/*                                          </span>*/}
            {/*                                        </>*/}
            {/*                                      ) : null}*/}
            {/*                                      {staff.staffContactNumber1 ? (*/}
            {/*                                        <>*/}
            {/*                                          <span>*/}
            {/*                                            {staff.staffContactNumber1}*/}
            {/*                                          </span>*/}
            {/*                                        </>*/}
            {/*                                      ) : null}*/}
            {/*                                    </div>*/}
            {/*                                  </div>*/}
            {/*                                );*/}
            {/*                              })}*/}
            {/*                            </div>*/}
            {/*                          </div>*/}
            {/*                        </div>*/}
            {/*                      ) : null}*/}
            {/*                    </>*/}
            {/*                  ) : (*/}
            {/*                    <div className="member col-md-3" key={key}>*/}
            {/*                      <div className="member-img">*/}
            {/*                        <img*/}
            {/*                          src={*/}
            {/*                            data.photoUrl*/}
            {/*                              ? data.photoUrl*/}
            {/*                              : "assets/images/avatar-placholder.png"*/}
            {/*                          }*/}
            {/*                          className="img-fluid"*/}
            {/*                          alt=""*/}
            {/*                        />*/}
            {/*                      </div>*/}
            {/*                      <div className="member-info">*/}
            {/*                        <h4>{data.nameNp}</h4>*/}
            {/*                        {data.staffDesignationNp ? (*/}
            {/*                          <>*/}
            {/*                            <span>{data.staffDesignationNp}</span>*/}
            {/*                          </>*/}
            {/*                        ) : null}*/}
            {/*                        {data.staffContactNumber1 ? (*/}
            {/*                          <>*/}
            {/*                            <span>{data.staffContactNumber1}</span>*/}
            {/*                          </>*/}
            {/*                        ) : null}*/}
            {/*                      </div>*/}
            {/*                    </div>*/}
            {/*                  )}*/}
            {/*                </>*/}
            {/*              );*/}
            {/*            })*/}
            {/*          : null}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<>*/}
            <div style={{display: 'flex', flexBasis: '33.33%', flexWrap: 'wrap'}} className="uk-margin-large" key={`${subHeaderItems.id}`}>
              {subHeaderItems.subHeaders && subHeaderItems.subHeaders.length > 0
                  ? subHeaderItems.subHeaders.map((data, key) => {
                          return (
                                  <div className="uk-width-1-4@m uk-width-1-2@s" key={key}>
                                      <div
                                          className="uk-panel uk-tile-default uk-padding-large uk-margin-remove-first-child uk-margin"
                                          data-id="page#7-0-1-1"
                                      >
                                          <picture className="aspect-sq">
                                              <img
                                                  decoding="async"
                                                  src={
                                                      data.photoUrl
                                                          ? data.photoUrl
                                                          : "assets/images/image.png"
                                                  }
                                                  alt=""
                                                  loading="lazy"
                                              />
                                          </picture>
                                          <h3 className="el-title uk-card-title uk-margin-small-top uk-margin-remove-bottom">
                                                  <>
                                                      <span>{languageStatus === 'en' ? data.name : data.nameNp }</span>
                                                  </>
                                          </h3>
                                          <div className="el-meta uk-text-meta">
                                                  <>
                                                      <span>{languageStatus === 'en' ? data.staffDesignation : (data.staffDesignationNp !== '' ? data.staffDesignationNp : data.staffDesignation)}</span>
                                                  </>
                                              <br />
                                              {data.staffContactNumber1 ? (
                                                  <>
                                                      <span>{languageStatus === 'en' ? data.staffContactNumber1 : engToNepNumber(data.staffContactNumber1)}</span>
                                                  </>
                                              ) : null}
                                          </div>
                                      </div>
                                  </div>
                          )
                      }
                  ) : null
              }
              </div>
          {/*</>*/}
      </>
    );
}

export default MemberDetail;
